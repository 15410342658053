import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import Select from 'react-select'

import FieldError from "../../helper/FieldError";

import { inputId, inputName, drawTooltipHelp, selectStyle } from '../../helper/form';

import CorporateCheckListAgreementProcessEntityTable from '../../corporate/check_list/agreement_process/entity_table'
import LaboralCheckListHiringTable from '../../laboral/check_list/hiring_process/entity_table';
import AbstractCheckListProcessTable from "../../abstract/check_list/process/entity_table";

import CheckListDocumentTypeList from "../document_type/list"

import I18n from 'i18n-js';
import _ from "lodash";

const CheckListProcessForm = props => {
  const currentEntityType = props?.currentEntityType
  const formName = props?.formName || 'check_list_process';
  const documentTypes = props?.documentTypes || [];
  const documentTypeOptions = _.map(documentTypes, dt => ({ value: dt.id, label: dt.name }))
  const [entityIds, setEntityIds] = useState(props?.entityIds || [] )

  const { createAction, updateAction, showPath, indexPath } = props?.actions;

  const [checkListProcess, setCheckListProcess] = useState(props?.checkListProcess || {})
  const [errors, setErrors] = useState(props?.errors || {})

  const handleProcessData = (event, key) => {
    let _checkListProcess = { ... checkListProcess }
    let value = event?.target?.value;

    if(event?.target?.type == 'checkbox'){
      value = event.target.checked;

      if(!value && key == 'requires_document_count_per_document_type'){
        _.each(checkListProcess.check_list_document_types, checkListDocumentType => {
          checkListDocumentType.document_count = 1
        })
      }
    }

    if(key == "entity_ids"){
      let entitySelectedIds = _.map(_.filter(checkListProcess.check_list_entities, checkListEntity => !checkListEntity._destroy), "entity_id")

      let newEntityIds = _.difference(value, entitySelectedIds);
      let removeEntityIds = _.difference(entitySelectedIds, value);

      _.each(newEntityIds, function(entityId){
        let item = _.find(_checkListProcess.check_list_entities, { entity_id: entityId });
        if(item){
          item._destroy = 0
        } else {
          _checkListProcess.check_list_entities.push({
            id: "",
            entity_id: entityId,
            _destroy: 0
          })
        }
      });

      _.each(removeEntityIds, function(entityId){
        let item = _.find(_checkListProcess.check_list_entities, { entity_id: entityId })
        if(item){
          item._destroy = 1
        }
      });
    } else {
      _checkListProcess[key] = value;
    }

    setCheckListProcess(_checkListProcess);
  }

  const handleMultiSelectData = (value, key) => {
    let values = _.map(value, 'value');
    let _checkListProcess = { ... checkListProcess }

    if(key == "document_type_ids"){

      let documentTypeSelectedIds = _.map(_.filter(checkListProcess.check_list_document_types, checkListDocumentType => !checkListDocumentType._destroy ), "document_type_id")

        // DocumentTypes
      let newDocumentTypeIds = _.difference(values, documentTypeSelectedIds);
      let removeDocumentTypeIds = _.difference(documentTypeSelectedIds, values);


      _.each(newDocumentTypeIds, function(documentTypeId){
        let item = _.find(_checkListProcess.check_list_document_types, { document_type_id: documentTypeId })
        if(item){
          item._destroy = 0
        } else {
          _checkListProcess.check_list_document_types.push({
            id: "",
            document_type_id: documentTypeId,
            document_count: 1,
            _destroy: 0
          })
        }
      });

      _.each(removeDocumentTypeIds, function(documentTypeId){
        let item = _.find(_checkListProcess.check_list_document_types, { document_type_id: documentTypeId })
        if(item){
          item._destroy = 1
          item.document_count = 1
        }
      });
    } else {
      _checkListProcess[key] = values;
    }

    setCheckListProcess(_checkListProcess);
  }

  const drawTextSubmitButton = () => {
    return I18n.t(`check_list.processes.form.${ checkListProcess.id ? 'update' : 'create' }.confirm.title`);
  }

  const handleSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t(`check_list.processes.form.${ checkListProcess.id ? 'update' : 'create' }.confirm.title`),
      html: I18n.t(`check_list.processes.form.${ checkListProcess.id ? 'update' : 'create' }.confirm.message`),
    }).then( result => {
      if(result.isConfirmed){
        handleSave()
      }
    })
  }

  const handleSave = () => {
    let formData = getFormData();
    // Con entity type
    if(_.keys(currentEntityType).length > 1) {
      if(checkListProcess.id){
        updateAction({id: checkListProcess.id, currentEntityTypeId: currentEntityType.hashid}, formData, response => {
          handleResponse(response)
        })
      } else {
        createAction(currentEntityType.hashid, formData, response => {
          handleResponse(response)
        })
      }
    } else {
      // Sin entity type
      if(checkListProcess.id){
        updateAction(checkListProcess.id, formData, response => {
          handleResponse(response)
        })
      } else {
        createAction(formData, response => {
          handleResponse(response)
        })
      }
    }

  }

  const handleResponse = (response) => {
    if(response.status == 200 || response.status == 201){

      if(_.keys(currentEntityType).length > 1){
        window.location = showPath(currentEntityType.hashid, response.data.id)
      } else {
        window.location = showPath(response.data.id)
      }
    } else {
      setErrors(response.data);
    }
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[name]`, checkListProcess.name || '');
    formData.append(`${ formName }[requires_autorefresh]`, checkListProcess?.requires_autorefresh);
    formData.append(`${ formName }[requires_document_count_per_document_type]`, checkListProcess?.requires_document_count_per_document_type);

    _.each(checkListProcess.check_list_document_types, function(checkListDocumentType, index){
      formData.append(`${ formName }[check_list_document_types_attributes][${ index }][id]`, checkListDocumentType?.id || "");
      formData.append(`${ formName }[check_list_document_types_attributes][${ index }][document_type_id]`, checkListDocumentType?.document_type_id || "");
      formData.append(`${ formName }[check_list_document_types_attributes][${ index }][document_count]`, Number(checkListDocumentType?.document_count) || 1);
      formData.append(`${ formName }[check_list_document_types_attributes][${ index }][_destroy]`, checkListDocumentType?._destroy || 0);
    });

    _.each(checkListProcess.check_list_entities, function(checkListEntity, index){
      formData.append(`${ formName }[check_list_entities_attributes][${ index }][id]`, checkListEntity?.id || "");
      formData.append(`${ formName }[check_list_entities_attributes][${ index }][entity_id]`, checkListEntity?.entity_id || "");
      formData.append(`${ formName }[check_list_entities_attributes][${ index }][_destroy]`, checkListEntity?._destroy || 0);
    })

    return formData;
  }

  const getSelectedDocumentTypeOptions = () => {
    let selectedDocumentTypeIds = _.map(_.filter(checkListProcess.check_list_document_types, checkListDocumentType => !checkListDocumentType._destroy), "document_type_id")

    let data = _.filter(documentTypeOptions, function(dt){
      return _.includes(selectedDocumentTypeIds.map(String), String(dt.value))
    })

    return data;
  }

  const drawEntityTableComponent = () => {
    if(props?.context == 'corporate'){
      return(
        <CorporateCheckListAgreementProcessEntityTable
          dataFilters={ props?.dataFilters }
          entityIds={ _.map(_.filter(checkListProcess.check_list_entities, checkListEntity => !checkListEntity._destroy), "entity_id") }
          customFields={ props?.customFields }
          currentUser={ props?.currentUser }
          callbackHandleProcessData={ handleProcessData }
          errors={ errors?.errors?.check_list_entities }
        />
      )
    } else if(props?.context == "laboral"){
      return(
        <LaboralCheckListHiringTable
          dataFilters={ props?.dataFilters }
          currentCustomer={ props?.currentCustomer }
          currentUser={ props?.currentUser }
          entityIds={ _.map(_.filter(checkListProcess.check_list_entities, checkListEntity => !checkListEntity._destroy), "entity_id") }
          customFields={ props?.customFields }
          callbackHandleProcessData={ handleProcessData }
          errors={ errors?.errors?.check_list_entities }
        />
      )
    } else if(props?.context == "abstract"){
      return(
        <AbstractCheckListProcessTable
          dataFilters={ props?.dataFilters }
          currentUser={ props?.currentUser }
          entityIds={ _.map(_.filter(checkListProcess.check_list_entities, checkListEntity => !checkListEntity._destroy), "entity_id") }
          callbackHandleProcessData={ handleProcessData }
          errors={ errors?.errors?.check_list_entities }
          currentEntityType={ currentEntityType }
          customFields={ props?.customFields }
        />
      )
    }
  }

  const requiresDocumentCountPerDocumentTypeSwitchInput = () => {
    return (
      <FormGroup>
        <FieldError errors={ errors?.requires_document_count_per_document_type || [] }>
          <div className="custom-control custom-switch mt-3">
            <Input
              className="custom-control-input"
              type="checkbox"
              id={ inputId(formName, 'requires_document_count_per_document_type') }
              name={ inputName(formName, 'requires_document_count_per_document_type') }
              invalid={ _.has(checkListProcess?.errors, 'requires_document_count_per_document_type') }
              onChange={ (e) => handleProcessData(e, 'requires_document_count_per_document_type') }
              checked={ checkListProcess?.requires_document_count_per_document_type || false }
            />
            <Label
              className="custom-control-label"
              htmlFor={ inputId(formName, 'requires_document_count_per_document_type') }
            >
              { I18n.t('activerecord.attributes.check_list/process.requires_document_count_per_document_type') }
            </Label>
          </div>
        </FieldError>
      </FormGroup>
    );
  };

  const checkListDocumentTypeList = () => {
    if(checkListProcess.requires_document_count_per_document_type){
      return (
        <CheckListDocumentTypeList
          formName={ formName }
          checkListProcess={ checkListProcess }
          setCheckListProcess={ setCheckListProcess }
          selectedDocumentTypeOptions={ getSelectedDocumentTypeOptions() }
          errors={ errors?.check_list_document_types }
        />
      )
    }
  }

  return(
    <div className="row">
      <div className="col-xs-12">
        <div className="card card-outline card-primary">
          <div className="card-body">

            {/*InputName*/}
            <div className="row">
              <div className="col-xs-12">
                <FormGroup>
                  <FieldError errors={ errors?.errors?.name}>
                    <Label
                      htmlFor={ inputId(formName, 'name') }
                    >
                      { I18n.t(`activerecord.attributes.check_list/process.name`) }
                    </Label>

                    <Input
                      id={ inputId(formName, 'name') }
                      onChange={ e => handleProcessData(e, 'name') }
                      value={ checkListProcess.name || '' }
                      invalid={ _.has(errors, 'name') }
                    />
                  </FieldError>
                </FormGroup>
              </div>
            </div>

            {/* InputRequiresAutorefresh */}
            <div className="row">
              <FormGroup>
                <div className="custom-control custom-switch">
                  <Input
                    className="custom-control-input"
                    type="checkbox"
                    id={ inputId(formName, 'requires_autorefresh') }
                    name={ inputName(formName, 'requires_autorefresh') }
                    invalid={ _.has(checkListProcess?.errors, 'requires_autorefresh') }
                    onChange={ e => handleProcessData(e, 'requires_autorefresh') }
                    checked={ checkListProcess?.requires_autorefresh || false }
                  />
                  <Label
                    className="custom-control-label"
                    for={ inputId(formName, 'requires_autorefresh') }
                  >
                    { I18n.t(`activerecord.attributes.check_list/process.requires_autorefresh`) }
                  </Label>
                  { drawTooltipHelp( I18n.t(`check_list.processes.requires_autorefresh.tooltip_info`) )}
                </div>
              </FormGroup>
            </div>

            {/*InputDocumentType*/}
            <div className="row">
              <div className="col-xs-12">
                <FormGroup>
                  <FieldError errors={ errors?.errors?.check_list_document_types }>
                    <Label
                      htmlFor={ inputId(formName, 'document_type_ids') }
                    >
                      { I18n.t(`activerecord.attributes.check_list/process.document_type_ids`) }
                    </Label>

                    <Select
                      isClearable
                      isMulti
                      options={ documentTypeOptions }
                      placeholder="-- Selecciona tipos de Documentos --"
                      onChange={ e => handleMultiSelectData(e, 'document_type_ids') }
                      value={ getSelectedDocumentTypeOptions() }
                      styles={ selectStyle }
                    />

                    <Button
                      color="primary"
                      className="mt-2"
                      onClick={ e => handleMultiSelectData(documentTypeOptions, 'document_type_ids') }
                    >
                      { I18n.t('check_list.processes.select_all') }
                    </Button>
                  </FieldError>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        {/*  ---------- START ADVANCED CONFIGURATION ---------- */}
        <div className="row">
          <div className="col-12">
            <div className="card hover-card card-material">
              <div className="card-header px-2 px-md-3 __with_collapsible-icons border-0">
                <a aria-controls="collapseAdvancedConfiguration" aria-expanded="false" className="font-weight-bold text-dark text-decoration-none d-block" data-bs-toggle="collapse" href="#collapseAdvancedConfiguration" role="button">
                  <i className="fas fa-cog mr-2"></i>
                  { I18n.t('advanced_settings') }
                </a>
              </div>
              <div className="collapse border-top" id="collapseAdvancedConfiguration">
                <div className="card-body lh-sm">
                  {/* requiresDocumentCountPerDocumentType */}
                  <div className="row">
                    { requiresDocumentCountPerDocumentTypeSwitchInput() }
                  </div>
                  { checkListDocumentTypeList() }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  ---------- END ADVANCED CONFIGURATION ---------- */}

        { drawEntityTableComponent() }

        <div className="text-end">
          <a href={ indexPath } className='btn btn-default'>
            { I18n.t('actions.back') }
          </a>

          <Button
            color='success'
            className='ml-2'
            onClick={ handleSubmit }
          >
            { drawTextSubmitButton() }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CheckListProcessForm;

