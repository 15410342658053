import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BulkRequestDestroyEmployeeTable from "./table";
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";
import { generateCustomFieldColumnFilter } from "../../../../helper/bootstrap-table/filter/helper";
import { fetchCustomFieldValue, isAuthorizedCustomFieldToUser } from "../../../../helper/custom_field/helper"
import { index as indexLaboralEmployee } from "../../../employee/axios";
import { create } from "../destroy_employee/axios";

import filterFactory, {
  textFilter,
  selectFilter
} from "react-bootstrap-table2-filter";
import _ from "lodash";

import { limitCharacters } from "../../../../helper/form"

const LaboralBulkRequestDestroyEmployeeForm = props => {
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);

  const { currentCustomer, securityLayers, currentUser } = props;
  const bootstrapTable = useRef(null);

  const hiringStateOptions = [ {value: 'active', label: I18n.t('laboral.hirings.index.tabs.active') }, { value: 'expired', label: I18n.t('laboral.hirings.index.tabs.expired')}]

  const getSecurityLayerColumn = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        filterByFilterrific: `by_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const columns = [
    {
      dataField: "identifier",
      text: I18n.t("activerecord.attributes.laboral/employee.identifier"),
      filterByFilterrific: "search_by_identifier",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/employee.identifier"
        )}`,
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "employee_config.name",
      text: I18n.t("activerecord.attributes.laboral/employee.name"),
      filterByFilterrific: "search_by_employee_name",
      sortByFilterrific: "name_",
      sort: true,
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/employee_config.name"
        )}`,
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "employee_config.email",
      text: I18n.t("activerecord.attributes.laboral/employee.email"),
      filterByFilterrific: "search_query",
      sortByFilterrific: "email_",
      sort: true,
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/employee_config.email"
        )}`,
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: I18n.t("activerecord.attributes.laboral/employee.created_at"),
      sortByFilterrific: "created_at_",
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "has_hiring_active",
      text: I18n.t("activerecord.attributes.laboral/employee.hiring_status"),
      filterByFilterrific: "by_hiring_state",
      filter: selectFilter({
        placeholder: 'Seleccione un estado de contratación',
        options: hiringStateOptions,
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => { return value ? I18n.t("laboral.employees.show.active_hiring") : I18n.t("laboral.employees.show.inactive_hiring")},
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    }
  ]

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        filterDisabled: !isAuthorizedCustomFieldToUser(custom, currentUser),
        filter: generateCustomFieldColumnFilter(custom),
        formatter: (value, row, index) => { return fetchCustomFieldValue(custom, value, currentUser) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const employeeColumns = _.concat(
    getSecurityLayerColumn(), columns, getCustomFields(props?.customFields)
  );

  return (
    <div>
      <BulkRequestDestroyEmployeeTable
        columns={employeeColumns}
        _ref={bootstrapTable}
        indexEmployee={indexLaboralEmployee}
        createService={create}
        bulkRequest={bulkRequest}
        buttonActions={{
          show: Routes.laboral_employee_path,
          back: Routes.laboral_employees_path(),
        }}
      />
    </div>
  )
}

export default LaboralBulkRequestDestroyEmployeeForm;