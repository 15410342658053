import React, {
  useEffect,
  useMemo,
  useContext
} from 'react';

import {
  FormGroup,
  Label
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {
  inputId,
  inputName
} from '../../helper/form';

import FieldError from '../../helper/FieldError';

import WorkflowRequirementStageFormContext from './context/form';

const WorkflowRequirementStageToStageAttributes = props => {

  const {
    requirementStage,
    configuration: {
      formName
    }
  } = props;

  const {
    data: {
      nextStages,
      returnStages
    },
    helpers: {
      isInputStage,
      isDefaultStage,
      isOutputStage,
    },
    callbacks: {
      onChangeRequirementStage
    }
  } = useContext(WorkflowRequirementStageFormContext)

  // -------------------- START NEXT STAGES --------------------

  const nextStageOptions = useMemo(() => {
    if(requirementStage.aasm_state_event == 'to_proceeded'){
      return nextStages;
    } else if(requirementStage.aasm_state_event == 'to_returned'){
      return returnStages;
    }
  })

  const nextStagesSelectOptions = useMemo(() => {
    return _.map(nextStageOptions, stage => {
      return {
        value: stage.id,
        label: stage.name
      }
    })
  }, [requirementStage.aasm_state_event])

  const nextStageSelectedOption = useMemo(() => {
    return  _.find(nextStagesSelectOptions, { value: requirementStage.to_stage_id })
  }, [requirementStage.to_stage_id])

  const displayComponent = useMemo(() => {
    return requirementStage.aasm_state_event != 'to_rejected' && requirementStage.aasm_state_event != 'to_completed'
  })

  const displayNextStageSelectInput = useMemo(() => {
    return requirementStage.aasm_state_event == 'to_proceeded' || requirementStage.aasm_state_event == 'to_returned'
  }, [requirementStage.aasm_state_event]);

  const disabledNextStageSelectInput = useMemo(() => {
    return isInputStage;
  }, [requirementStage.node_type])


  const nextStageSelectInput = () => {
    if(displayNextStageSelectInput){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirementStage, 'errors.to_stage_id') || [] }>
            <Label
              className={ 'required' }
              htmlFor={ inputId(formName, 'to_stage_id') }
            >
              { I18n.t(`activerecord.attributes.workflow/requirement_stage.to_stage_id.${ requirementStage.aasm_state_event }`) }
            </Label>
            <Select
              id={ inputId(formName, 'to_stage_id') }
              name={ inputName(formName, 'to_stage_id') }
              options={ nextStagesSelectOptions }
              value={ nextStageSelectedOption }
              onChange={ e => onChangeRequirementStage({ target: { value: e?.value }}, 'to_stage_id') }
              placeholder={ '-- Selecciona la siguiente etapa --' }
              isDisabled={ disabledNextStageSelectInput }
            />
          </FieldError>
        </FormGroup>
      )
    } else {
      return <></>
    }
  }
  // -------------------- END NEXT STAGES --------------------

  // ----------------------------------------

  // -------------------- START AUTHORIZED EMAILS --------------------

  useEffect(() => {
    // En caso de que solo haya una etapa, se selecciona por defecto
    if(nextStagesSelectOptions.length == 1){
      onChangeRequirementStage({ target: { value: nextStagesSelectOptions[0].value } }, 'to_stage_id')
    }
  }, [])

  useEffect(() => {
    if(requirementStage.to_stage_id){
      const emails = _.map(toStageDefaultAuthorizedUsers, 'email');

      onChangeRequirementStage({ target: { value: emails } }, 'to_stage_authorized_emails');
    } else {
      onChangeRequirementStage({ target: { value: [] } }, 'to_stage_authorized_emails')
    }
  }, [requirementStage.to_stage_id])

  const displayToStageAuthorizedEmailsSelectInput = useMemo(() => {
    return displayNextStageSelectInput
  }, [requirementStage.aasm_state_event]);


  const toStageDefaultAuthorizedUsers = useMemo(() => {
    const stages = _.concat(nextStages, returnStages);

    const nextStage = _.find(stages, { id: requirementStage.to_stage_id });

    if(_.has(nextStage, 'group.users')){
      return nextStage.group.users;
    } else {
      return []
    }
  }, [requirementStage.to_stage_id])

  const toStageAuthorizedEmailsSelectedOptions = useMemo(() => {
    const emails = requirementStage.to_stage_authorized_emails;

    return _.map(emails, email => {
      return { label: email, value: email }
    })
  }, [requirementStage.to_stage_authorized_emails])

  const toStageAuthorizedEmailsOptions = useMemo(() => {
    const defaultOptions = toStageDefaultAuthorizedUsers;

    return _.map(defaultOptions, user => {
      return { label: user.email, value: user.email }
    })
  }, [toStageDefaultAuthorizedUsers])

  const onChangeToStageAuthorizedEmails = event => {
    const values = _.map(event, 'value');

    onChangeRequirementStage({ target: { value: values }}, 'to_stage_authorized_emails');
  }

  const toStageAuthorizedEmailsSelectInput = () => {
    if(displayToStageAuthorizedEmailsSelectInput && toStageDefaultAuthorizedUsers.length > 0){
      const formName = `${ formName }[current_requirement_stage_attributes]`

      return(
        <FormGroup>
          <FieldError errors={ _.get(requirementStage, 'errors.to_stage_authorized_emails') || [] }>
            <Label
              className={ 'required' }
              htmlFor={ inputId(formName, 'to_stage_authorized_emails') }
            >
              { I18n.t('activerecord.attributes.workflow/requirement_stage.to_stage_authorized_emails') }
            </Label>

            <CreatableSelect
              isMulti={ true }
              id={ inputId(formName, 'to_stage_authorized_emails') }
              name={ inputName(formName, 'to_stage_authorized_emails') }
              options={ toStageAuthorizedEmailsOptions }
              value={ toStageAuthorizedEmailsSelectedOptions }
              onChange={ e => onChangeToStageAuthorizedEmails(e) }
              placeholder={ '-- Selecciona los responsables de las siguiente etapa --'}
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  // -------------------- END AUTHORIZED EMAILS --------------------

  return(
    <div className="row">
      <div className="col-12">
        { function(){
          if(displayComponent){
            return(
              <div className="row">
                <div className="col-12">
                  <div className="card card-material">
                      <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                          <div className="font-weight-bold">
                            Configuración Siguiente etapa
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        { nextStageSelectInput() }
                        { toStageAuthorizedEmailsSelectInput() }
                      </div>
                    </div>
                </div>
              </div>
            )
          }
        }()}
      </div>
    </div>
  )
}

export default WorkflowRequirementStageToStageAttributes;
