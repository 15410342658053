import React, {
  useCallback,
  useMemo,
  useContext,
  useRef,
  useState
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import I18n from 'i18n-js';

import FieldError from "../../helper/FieldError";

import {
  inputId,
  inputName,
} from '../../helper/form';

import WorkflowDiagramContext from '../diagram_context';

const WorkflowStageFieldItemAttributes = props => {

  const formName = _.uniqueId('stage_item_field')

  const [openModalAdvancedConfiguration, setOpenModalAdvancedConfiguration] = useState(false)
  const [openModalListOptions, setOpenModalListOptions] = useState(false)

  const {
    fieldItem,
    index,
    currentNode,
    setCurrentNode,
    configuration: {
      stageForm,
      showGroupName
    },
    data: {
      groupNameOptions =  []
    }
  } = props;

  const {
    data: {
      fieldItem: {
        dataTypes: fieldItemDataTypes
      }
    },
  } = useContext(WorkflowDiagramContext);

  const setFieldItem = _fieldItem => {
    setCurrentNode(prevState => {
      prevState.data[stageForm]['field_items'][index] = {
        ...prevState.data[stageForm]['field_items'][index],
        ..._fieldItem
      }

      return { ... prevState }
    })
  }

  const onChangeField = (event, key) => {
    // Si el campo se encuentra desabilitado no se debe poder modificar
    if(!fieldItem.is_disabled){
      const type = event.target.type;
      let value = event.target.value;

      let fieldChange = {}

      if(type == 'checkbox'){
        value = event.target.checked;
      }

      fieldChange[key] = value

      setCurrentNode(prevState => {
        prevState.data[stageForm]['field_items'][index] = {
          ...prevState.data[stageForm]['field_items'][index],
          ...fieldChange
        }

        return { ... prevState }
      })
    }
  }

  const nameInput = () => {
    return(
      <td className='text-center'>
        <FormGroup>
          <FieldError errors={ fieldItem?.errors?.name || [] }>
            <Input
              value={ fieldItem?.name || '' }
              onChange={ e => onChangeField(e, 'name') }
              invalid={ _.has(fieldItem, 'errors.name') }
              disabled={ fieldItem?.is_disabled || false }
            />
          </FieldError>
        </FormGroup>
      </td>
    )
  }

  const dataTypeSelectInput = () => {
    return(
      <td className='text-center'>
        <FormGroup>
          <FieldError errors={ fieldItem?.errors?.data_type || [] }>
            <Input
              type='select'
              value={ fieldItem?.data_type || '' }
              onChange={ e => onChangeField(e, 'data_type') }
              invalid={ _.has(fieldItem, 'errors.data_type') }
              disabled={ fieldItem?.is_disabled || false }
            >
              <option value=""></option>
              { _.map(fieldItemDataTypes, (value, label) => {
                return(
                  <option
                    value={ value }
                    key={ _.uniqueId(`option-data-type`) }
                  >
                    { I18n.t(`activerecord.attributes.workflow/stage_field_item.data_type.${ label }`) }
                  </option>
                )
              })}
            </Input>
          </FieldError>
        </FormGroup>
        { listOptionSelectInput() }
      </td>
    )
  }

  const isRequiredSwitchInput = () => {
    return(
      <td className='text-center'>
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                value={ '1' }
                onChange={ e => onChangeField(e, 'is_required') }
                id={ inputId(formName, 'is_required') }
                name={ inputName(formName, 'is_required') }
                checked={ fieldItem.is_required || false }
                disabled={ fieldItem?.is_disabled || false }
              />
              <Label
                className='custom-control-label'
                htmlFor={ inputId(formName, 'is_required') }
              ></Label>
            </FieldError>
          </div>
        </FormGroup>
      </td>
    )
  }

  // ---------- START LIST OPTIONS --------

  const toggleOpenModalListOptions = () => {
    setOpenModalListOptions(!openModalListOptions)
  }

  const listOptionsSelectSave = () => {
    toggleOpenModalListOptions();
  };

  const listOptionsSelectOptions = () => {
    if (fieldItem.data_type == 'list'){
      return _.map(fieldItem.list_options, option => {
        return {
          label: option,
          value: option
        }
      })
    }
  }

  const listOptionSelectInput = () => {
    if (stageForm === "output_stage_form" && fieldItem.data_type == 'list') {
      return (
        <span className='text-center'>
          <FieldError errors={ fieldItem?.errors?.list_options || [] }>
            <FormGroup>
              <Button color="primary" size="sm" outline onClick={ toggleOpenModalListOptions }>
                <i class="fas fa-list mr-1"></i>
                { I18n.t('workflow.stage_field_items.form.complete_list_options') }
              </Button>
            </FormGroup>
          </FieldError>

          <Modal isOpen={ openModalListOptions } toggle={ toggleOpenModalListOptions }>
            <ModalHeader toggle={ toggleOpenModalListOptions }> 
              { I18n.t('workflow.stage_field_items.form.config_list_options') } 
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">Para crear las opciones de la lista:</p>
              <ol className="mb-3 pl-3">
                <li>Escribe la primera opción en el campo de texto</li>
                <li>Presiona Enter para agregarla</li>
                <li>Repite el proceso para cada opción que necesites</li>
                <li>Puedes eliminar opciones haciendo clic en la "x"</li>
              </ol>
              <CreatableSelect
                isClearable
                isMulti
                defaultValue={ listOptionsSelectOptions() }
                onChange={ value => onChangeField({ target: { value: value?.map(option => option.value) || [] } }, 'list_options') }
                isDisabled={ fieldItem.data_type !== "list" }
                placeholder="Escribe una opción y presiona Enter"
                noOptionsMessage={ ()=>"Escribe para crear la primera opción"}
                formatCreateLabel={inputValue => `Crear "${inputValue}"`}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={ toggleOpenModalListOptions }>
                { I18n.t('actions.close') }
              </Button>
              <Button color="success" onClick={ listOptionsSelectSave }>
                { I18n.t('actions.save') }
              </Button>
            </ModalFooter>
          </Modal>
        </span>
      );
    }
  };

  //----------- END LIST OPTIONS ----------

  // ---------- START GROUP NAME ----------

  const groupNameSelectOptions = () => {
    return _.map(groupNameOptions, option => {
      return {
        label: option,
        value: option
      }
    })
  }

  const groupNameSelectedOption = useMemo(() => {
    if(_.includes(groupNameOptions, fieldItem.group_name)){
      return {
        label: fieldItem.group_name,
        value: fieldItem.group_name,
      }
    } else {
      return null
    }
  }, [fieldItem.group_name])

  const onCreateOptionGroupName = value => {
    onChangeField(
      { target: { value: value }},
      'group_name'
    )
  }

  const groupNameSelectInput = () => {
    if(showGroupName){
      return(
        <td className='text-center'>
          <FormGroup>
            <CreatableSelect
              isClearable
              options={ groupNameSelectOptions() }
              onChange={ e => onChangeField({ target: { value: e?.value}}, 'group_name') }
              onCreateOption={ onCreateOptionGroupName }
              value={ groupNameSelectedOption }
              placeholder="Crea o Selecciona Grupo de Datos"
            />
          </FormGroup>
        </td>
      )
    }
  }

  // ---------- END GROUP NAME ----------

  // ---------- START ADVANCED CONFIGURATION  ----------
  const advancedConfigurationButton = () => {
    if(stageForm == 'output_stage_form'){
      return(
        <td>
          <Button
            outline
            color='primary'
            size='xs'
            onClick={ e => setOpenModalAdvancedConfiguration(true) }
          >
            <i className="fas fa-cog"></i>
          </Button>
        </td>
      )
    }
  }

  const toggleOpenModalAdvancedConfiguration = () => {
    setOpenModalAdvancedConfiguration(!openModalAdvancedConfiguration)
  }

  const advancedConfigurationModal = () => {
    return(
      <WorkflowStageFieldItemModalAdvancedConfiguration
        openModalAdvancedConfiguration={ openModalAdvancedConfiguration }
        currentNode={ currentNode }
        setCurrentNode={ setCurrentNode }
        fieldItem={ fieldItem}
        configuration={
          { ... props?.configuration, formName: formName }
        }
        callbacks={{
          toggleOpenModalAdvancedConfiguration: toggleOpenModalAdvancedConfiguration,
          setFieldItem: setFieldItem
        }}
      />
    )
  }
  // ---------- END ADVANCED CONFIGURATION  ----------

  const deleteButton = () => {
    return(
      <td className='text-center'>
        <Button
          outline
          color='danger'
          size='xs'
          onClick={ e => onChangeField({ target: { value: true } }, '_destroy') }
          disabled={ fieldItem?.is_disabled || false }
        >
          <i className="fas fa-trash"></i>
        </Button>
      </td>
    )
  }



  return(
    <tr className={ `${ fieldItem._destroy ? 'd-none' : '' } ` }>
      { groupNameSelectInput() }
      { nameInput() }
      { dataTypeSelectInput() }
      { isRequiredSwitchInput() }
      { advancedConfigurationButton() }
      { deleteButton() }
      { advancedConfigurationModal() }
    </tr>
  )
}

export default WorkflowStageFieldItemAttributes;


const WorkflowStageFieldItemModalAdvancedConfiguration = props => {
  const {
    openModalAdvancedConfiguration,
    currentNode,
    setCurrentNode,
    callbacks: {
      toggleOpenModalAdvancedConfiguration: callbackToggleOpenModalAdvancedConfiguration,
      setFieldItem: callbackSetFieldItem
    },
    configuration: {
      formName,
      stageForm
    },
  } = props;

  const {
    callbacks: {
      isNodeInput,
    }
  } = useContext(WorkflowDiagramContext);

  const [fieldItem, setFieldItem] = useState({});

  const setDefaultFieldItem = event => {
    setFieldItem(props?.fieldItem);
  }

  const onChangeFieldItem = (event, key) => {
    const type = event.target.type;
    let value = event?.target?.value;

    if(type == 'file'){
      value = event?.target?.files[0]
    }

    setFieldItem( prevState => {
      return { ... prevState, [key]: value}
    })
  }

  // ---------- START HELPING TEXT ----------
  const helpingTextInput = () => {
    return(
      <FieldError errors={ fieldItem?.errors?.helping_text || [] }>
        <FormGroup>
          <Label
            htmlFor={ inputId(formName, 'helping_text') }
          >
            { I18n.t('activerecord.attributes.workflow/stage_field_item.helping_text') }
          </Label>
          <Input
            type='textarea'
            onChange={ e => onChangeFieldItem(e, 'helping_text') }
            id={ inputId(formName, 'helping_text') }
            name={ inputName(formName, 'helping_text') }
            value={ fieldItem?.helping_text || '' }
          />
        </FormGroup>
      </FieldError>
    )
  }

  // ---------- END HELPING TEXT ----------

  const saveAdvancedConfiguration = () => {
    callbackToggleOpenModalAdvancedConfiguration()
    callbackSetFieldItem(fieldItem);
  }

  // ---------- START BRIDGE INFORMATION ----------

  const onChangeRequireAutcompleteByInputData = event => {
    const { checked }  = event.target;

    let bridgeFieldItems = [... currentNode.data.bridge_field_items];

    const index = _.findIndex(bridgeFieldItems, fi => {
      return String(fi.output_field_custom_id) == String(fieldItem.custom_id)
    })

    if(index >= 0){
      bridgeFieldItems[index]['require_autocomplete_by_input_data'] = checked;
    } else {
      bridgeFieldItems.push({
        output_field_custom_id: fieldItem.custom_id,
        require_autocomplete_by_input_data: checked
      })
    }

    setCurrentNode(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          bridge_field_items: bridgeFieldItems
        }
      }
    })
  }


  const onChangeAutocompleteByInputData = (event, key) => {
    let value = event.target.value;

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }

    let bridgeFieldItems = [... currentNode.data.bridge_field_items];

    const index = _.findIndex(bridgeFieldItems, fi => {
      return String(fi.output_field_custom_id) == String(fieldItem.custom_id)
    })

    if(index >= 0){
      bridgeFieldItems[index]['input_field_custom_id'] = value;
    } else {
      bridgeFieldItems.push({
        output_field_custom_id: fieldItem.custom_id,
        input_field_custom_id: value
      })
    }

    setCurrentNode(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          bridge_field_items: bridgeFieldItems
        }
      }
    })
  }

  const requireAutocompleteByInputData = useMemo(() => {
    const currentCustomId = fieldItem.custom_id;

    const currentBridgeInformation = _.find(currentNode.data.bridge_field_items, { 'output_field_custom_id': currentCustomId })
    return !!currentBridgeInformation?.require_autocomplete_by_input_data
  }, [fieldItem.custom_id, currentNode.data.bridge_field_items])

  const requireAutompleteByInputDataSwitch = () => {
    if(stageForm == 'output_stage_form' && !isNodeInput(currentNode)){
      return(
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                value={ requireAutocompleteByInputData }
                // name={ `${ inputName( formName, 'clone_folder_ids')  }[]` }
                id={ inputId( formName, `require_autocomplete_by_input_data_${ fieldItem.custom_id }`) }
                onChange={ event => onChangeRequireAutcompleteByInputData(event, 'require_autocomplete_by_input_data') }
                checked={ requireAutocompleteByInputData }
  
              />
              <Label
                className='custom-control-label'
                htmlFor={ inputId( formName, `require_autocomplete_by_input_data_${ fieldItem.custom_id }`) }
              >
                Autocompletar
              </Label>
            </FieldError>
          </div>
        </FormGroup>
      )
    }
  }

  const autocompleteInputFieldCustomIdSelectedOption = useMemo(() => {
    const data = _.find(currentNode?.data?.bridge_field_items, { output_field_custom_id: fieldItem.custom_id })
    if(data){
      const inputFieldItem = _.find(currentNode?.data?.input_stage_form.field_items, { custom_id: data.input_field_custom_id })

      return inputFieldItem ? { value: inputFieldItem.custom_id, label: inputFieldItem.name } : null
    } else {
      return null
    }
  }, [fieldItem, currentNode?.data.input_stage_form, currentNode?.data?.bridge_field_items])

  const autocompleteInputFieldCustomIdSelectOptions = () => {
    return _.map(currentNode?.data?.input_stage_form.field_items, _fieldItem =>({
      value: _fieldItem.custom_id,
      label: _fieldItem.name
    }))
  }

  const autocompleteByInputDataSelect = () => {
    if(stageForm == 'output_stage_form' && requireAutocompleteByInputData){
      return(
        <FormGroup >
          <FieldError errors={ [] }>
            <Select
              id={ inputId( formName, `input_field_custom_id_${ fieldItem.custom_id }`) }
              onChange={ e => onChangeAutocompleteByInputData({ target: { value: e?.value } }, 'input_field_custom_id' ) }
              value={ autocompleteInputFieldCustomIdSelectedOption }
              options={ autocompleteInputFieldCustomIdSelectOptions() }
              placeholder={ `-- Selecciona un dato de entrada --` }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  // ---------- END BRIDGE INFORMATION ----------

  return(
    <Modal
      isOpen={ openModalAdvancedConfiguration }
      toggle={ callbackToggleOpenModalAdvancedConfiguration }
      unmountOnClose={ true }
      size={ 'lg' }
      onOpened={ event => setDefaultFieldItem() }
    >
      <ModalHeader
        toggle={ callbackToggleOpenModalAdvancedConfiguration }
      >
        Configuración Avanzada
        <small className='d-block text-muted'>
          { `${ currentNode?.data?.label || 'Etapa sin nombre'} - ${ fieldItem.name }`}
        </small>
      </ModalHeader>
      <ModalBody>
        { helpingTextInput() }
        { requireAutompleteByInputDataSwitch() }
        { autocompleteByInputDataSelect() }
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={ callbackToggleOpenModalAdvancedConfiguration }>
          { I18n.t('actions.close') }
        </Button>{' '}
        <Button color="success" onClick={ e => saveAdvancedConfiguration() }>
          { I18n.t('actions.save') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}
