import React, { useCallback } from 'react';
import UserProfileFormAttributes from '../../user/profile/form_attributes';

const PortalProfileForm = props => {

  const {
    user,
    data,
    configuration: {
      context = ''
    } = {}
  } = props;

  const redirectDashboardPortalButton = () => {
    if(
      context == 'portal' &&
      data?.currentCustomer &&
      user.completed_personal_information &&
      user.completed_signature_information
    ){
      return(
        <div className="text-end">
          <a
            href={ Routes.portal_path(data.currentCustomer.portal_configuration.tenant) }
            className="btn btn-md btn-light mb-1 mr-2 d-inline-block"
          >
            Ir al portal
          </a>
        </div>
      )
    }
  }

  const redirectAfterProfileCompleted = useCallback(() => {
    window.location = Routes.portal_path(data.currentCustomer.portal_configuration.tenant)
  }, [])

  return(
    <React.Fragment>
      <UserProfileFormAttributes
        user={ user }
        data={ data }
        callbacks={{
          redirectAfterProfileCompleted: redirectAfterProfileCompleted
        }}
      />

      { redirectDashboardPortalButton() }
    </React.Fragment>
  )
}

export default PortalProfileForm;
