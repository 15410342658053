import React, { useState, useEffect } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Files from "react-butterfiles";

import I18n from 'i18n-js';

import { index as indexLaboralHiring } from "../../../hiring/axios";

const LaboralBulkLoadHiringDocumentFileInput = props => {
  const maxSize = 10;
  const maxFiles = 100;


  const hiringDocuments = props?.hiringDocuments;
  const setHiringDocuments = props?.setHiringDocuments;

  const [hirings, setHirings] = useState([]);

  const [files, setFiles] = useState([]);

  const helperTypeErrorFile = type => {
    switch (type){
      case 'unsupportedFileType':
        return 'Formato del archivo no soportado'
      case 'maxSizeExceeded':
        return `El archivo excede el tamaño máximo de ${ maxSize }mb`
      case 'WithoutHiring':
        return 'No hemos encontado contratación para el archivo'
      case 'multipleMaxCountExceeded':
      return `No puedes cargar más de ${ maxFiles } archivos`
      default:
      return 'Error en el archivo'
    }
  }

  const drawErrorsFile = () => {
    if((props?.errorsFile || []).length > 0){
      return(
        <ul className='text-danger mt-3'>
          { _.map(props?.errorsFile, function(data){
            return(
              <li
                key={ _.uniqueId('file-error-') }
              >
                { `${ data?.file?.name || 'Error' }: ${ helperTypeErrorFile(data.type) }.` }
              </li>
            )
          })}
        </ul>
      )
    }
  }

  const filename = file =>  {
    // cuando el identificador de la contratación es un RUT con puntos, el nombre del archivo viene en el siguiente formato
    // 11.111.111-1.pdf
    // Por lo que tenemos que obtener solo el valor 11.111.111-1
    let nameWithoutExtension = _.replace(file.name, /\.[^\/.]+$/, '')
    let nameOnlyIdentifier = _.split(nameWithoutExtension, '_')[0]
    return nameOnlyIdentifier
  }

  const getHirings = (identifiers) => {
    let requestParams = {
      'filterrific': {
        'search_by_identifiers': identifiers,
        'show_all': true
      }
    }

    indexLaboralHiring(requestParams, response => {
      if(response.status == 200){
        setHirings(response.data.hirings);
      }
    });
  }

  useEffect(() => {
    matchFilesAndHirings()
  }, [hirings])

  const matchFilesAndHirings = () => {
    let _files = [];
    let _errors = [ ... props?.errorsFile ];
    _.each(files, function(file){
      let _filename = filename(file);
      let _hiring = _.find(hirings, { identifier: _filename });

      if(_hiring){
        _files = _.concat(_files, { hiring: _hiring, file: file } );
      } else {
        _errors = _.concat(_errors, { file: file, type: 'WithoutHiring' } )
        props?.setErrorsFiles(_errors)
      }

    })

    _files = _.sortBy(_files, function(a){ return  a.hiring.employee.name })

    setHiringDocuments(_files);
  }

  const handleFiles = __files => {
    let identifiers = _.map(__files, file => filename(file));


    getHirings(identifiers);
    setFiles(__files);
  }

  const handleDeleteHiringDocument = (e, index) => {
    e.preventDefault();
    let _hiringDocuments = [...hiringDocuments];

    let data = _.remove(_hiringDocuments, function(hrDocument, _index){
      return _index == index
    })
    setHiringDocuments(_hiringDocuments)
  }

  const handleDeleteAllHiringDocuments = () => {
    swalWithBootstrap.fire({
      title: 'Eliminar documentos',
      html: '¿Estás seguro de eliminar todos los documentos?',
    }).then( result => {
      if(result.isConfirmed){
        setHiringDocuments([])
      }
    })
  }

  const drawFiles = () => {
    if(hiringDocuments.length > 0){
      return(
        <div className="row mt-3">
            { _.map(hiringDocuments, function(hrDocument, index){
              return(
                <div className="col-12" key={ _.uniqueId('file-list-') }>
                  <div className="card mb-3 clip">
                    <div className="card-body">
                      <div className="row d-flex align-items-center">
                        <div className="row">
                          <div className="col-md-8">
                            <h2 className="h6 text-dark font-weight-bold d-block text-capitalize mb-2">
                              { hrDocument.hiring.employee.name }
                            </h2>

                            <ul className="list-unstyled list-inline text-muted mb-2 mb-sm-0 small">
                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.laboral/hiring.identifier') }
                                { `: ${ hrDocument.hiring.identifier }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.laboral/employee.email') }
                                { `: ${ hrDocument.hiring?.employee?.email }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.laboral/hiring.company') }
                                { `: ${ hrDocument.hiring?.company?.name }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.laboral/hiring.start_date') }
                                { `: ${ hrDocument.hiring.start_date_to_s }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.laboral/hiring.end_date') }
                                { `: ${ hrDocument.hiring.end_date_to_s || 'Actualidad' }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { 'Documento' }
                                { `: ${ hrDocument.file.name || 'Actualidad' }`}
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-4 text-end">
                            <a
                              href='#'
                              className='text-danger'
                              onClick={ e => handleDeleteHiringDocument(e, index) }
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <Files
          multiple={ true }
          maxSize={ `${ maxSize }mb`}
          multipleMaxSize={ `${ maxSize * maxFiles }mb`}
          multipleMaxCount={ maxFiles }
          accept={ _.map(props?.allowedFileFormats, 'content_type') }
          onError={ errors => {
            props?.setErrorsFiles(errors)
          } }
          onSuccess={ response => {
            handleFiles(response)
          } }
        >
          { ( { browseFiles, getDropZoneProps }) => {
            return(
              <div className="row">
                <div className="col-12">
                  <span className="font-weight-bold text-muted">
                    Formatos permitidos: { _.join(_.map(props?.allowedFileFormats, 'extension'), ' ') }
                  </span>
                  <div className='text-center pt-5 text-muted mb-3'
                    {...getDropZoneProps({
                      style: {
                        minHeight: 150,
                        border: "2px lightgray dashed",
                      },
                    })}
                  >
                    <i className="fas fa-file-upload fa-4x"></i>
                    <p className='mt-3'>
                      Arrastra y suelta tus archivos aquí
                    </p>

                    <p>{ hiringDocuments.length } archivos cargados</p>
                  </div>

                  { drawErrorsFile() }

                  <button
                    onClick={ browseFiles }
                    className='btn btn-success btn-sm'
                  >
                    Subir archivos
                  </button>
                  <button
                    className='btn btn-danger btn-sm ml-1'
                    onClick={ e => handleDeleteAllHiringDocuments(e) }
                  >
                    Eliminar archivos
                  </button>
                </div>
              </div>
            )
          }}
        </Files>

        { drawFiles() }
      </div>
    </div>
  )
}

export default LaboralBulkLoadHiringDocumentFileInput;
