import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import I18n from 'i18n-js';

import {
  inputId,
  inputName,
  drawTooltipDocumentFile,
  drawTooltipHelp
} from '../helper/form';

import FieldError from '../helper/FieldError';

import DocumentSignerConfigAttributes from './signer_config_attributes';
import SignerFormList from '../signer/form_list';
import SignerGroupFormList from '../signer_group/form_list';
import DocumentPreview from './preview';

import {
  onChangeDocumentHelper
} from './helper'

import DocumentFormContext from './context/document_form_context';
import DocumentSignerConfigContext from './context/signer_config_context'

const DocumentAttributes = props => {

  const { data, callbackSetDocument, currentEntityType } = props;
  const {
    indexEntities,
    createDocument,
    updateDocument,
    showDocumentPath,
    indexDocumentsPath,
    showCheckListTaskPath
  } = props?.actions;

  const {
    entity: entityConfiguration,
    formName,
    context,
    defaultRequestParams
  } = props?.configuration;

  const [_document, setDocument] = useState(props?._document || {});
  const [entities, setEntities] = useState(props?.data?.entities || [])
  // Store the file to preserve its value if form submission fails,
  // avoiding the need to re-upload it
  const [selectedFile, setSelectedFile] = useState(null);

  const documentFormContextValue = useMemo(() => {
    return {
      currentUser: props?.currentUser,
      currentCustomer: props?.currentCustomer,
      currentEntityType: props?.currentEntityType,
      isPersisted: isPersisted,
      context: context,
      data: data,
      _class: props?.configuration?._class,
      entities: entities
    }
  }, [entities])

  const documentSignerConfigContextValue = useMemo(() => {
    return {
      resource: {
        require_signers: _document?.require_signers,
        require_signers_order: _document?.require_signers_order,
        require_fao: _document?.require_fao,
        entity_id: _document?.entity_id,
        isPersisted: false
      },
    }
  }, [
    _document?.require_signers,
    _document?.require_signers_order,
    _document?.require_fao,
    _document?.entity_id
  ])

  // Start Entities
  useEffect(() => {
    if(entities.length == 0){
      fetchDefaultEntities()
    }
  }, [])

  const fetchDefaultEntities = useCallback(() => {
    indexEntities(defaultRequestParams, response => {
      let _entities = response.data[`${ entityConfiguration.key }`];
      setEntities(_entities)
    })
  }, [])

  const fetchEntities = useCallback((inputValue, callback) => {
    let requestParams = defaultRequestParams;

    requestParams['filterrific'] = { search_query: inputValue };

    indexEntities(requestParams, response => {
      let _entities = response.data[`${ entityConfiguration.key }`];
      let options = _.map(_entities, entity => ({ value: entity.id, label: entity.name }))
      setEntities(_entities);
      callback(options);
    })
  }, [])

  const entitySelectedOption = useMemo(() => {
    const selected = _.find(entities, { id: _document?.entity_id })
    if(selected){
      return { value: selected?.id, label: selected?.name }
    } else {
      return null
    }
  }, [_document?.entity_id])

  const entitySelectOptions = useMemo(() => {
    return _.map(entities, function(entity){
      return { value: entity.id, label: entity.name}
    })
  }, [entities])

  const entitySelectInputDisabled = useMemo(() => {
    return !!props?.data?.checkListTaskId || !!data?.entityId
  }, [])

  const signersCount = useMemo(() => {
    return _.filter(_document.signers, signer =>{ return signer._destroy ==  false})?.length;
  }, [_document?.signers])

  const drawEntitySelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _document?.errors?.entity_id || [] }>
          <Label
            for={ inputId(formName, 'entity_id') }
            className="required"
          >
            { entityConfiguration.label }
          </Label>

          <AsyncSelect
            id={ inputId(formName, 'entity_id') }
            name={ inputName(formName, 'entity_id') }
            invalid={ _.has(_document?.errors, 'entity_id') }
            value={ entitySelectedOption }
            onChange={ e => handleDocument({ target: { value: e?.value } }, 'entity_id' ) }
            isDisabled={ entitySelectInputDisabled }
            placeholder={ entityConfiguration?.placeholder }
            isClearable={ true }
            loadOptions={ fetchEntities }
            cacheOptions={ true }
            defaultOptions={ entitySelectOptions }
            options={ entitySelectOptions }
            noOptionsMessag={ e => 'Recurso no encontrado' }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // End Entities

  // Start DocumentTypes
  const documentTypeSelectOptions = useMemo(() => {
    return _.map(data?.documentTypes || [], documentType => ({ value: documentType.id, label: documentType.name }))
  }, [])

  const documentTypeSelectedOption = useMemo(() => {
    let selected = _.find(data?.documentTypes, { id: _document?.document_type_id } )

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }, [_document?.document_type_id])

  const documentTypeSelectInputDisabled = useMemo(() => {
    return !!props?.data?.checkListTaskId || isPersisted
  }, [])

  const drawDocumentTypeSelectInput = () => {
    let isDisabled = documentTypeSelectInputDisabled;

    return(
      <FormGroup>
        <FieldError errors={ _document?.errors?.document_type_id || [] }>
          <Label
            for={ inputId(formName, 'document_type_id') }
            className="required"
          >
            { I18n.t('activerecord.attributes.document.document_type_id') }
          </Label>

          <Select
            id={ inputId(formName, 'document_type_id') }
            name={ inputName(formName, 'document_type_id') }
            invalid={ _.has(_document?.errors, 'document_type_id') }
            value={ documentTypeSelectedOption }
            onChange={ e => handleDocument({ target: { value: e?.value }}, "document_type_id") }
            options={ documentTypeSelectOptions }
            isDisabled={ isDisabled }
            isClearable={ true }
            placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.document.document_type_id') } --` }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // End DocumentTypes

  // Start Label
  const drawDocumentFileInfo = useMemo(() => {
    return(
      <ul className='text-muted small'>
        <li>{ `${ I18n.t('documents.form.allowed_format', { allowed_format: _.join(_.map(data?.allowedFileFormats, 'extension'), ' ')}) }` }</li>
        <li>{ `${ I18n.t('documents.form.max_size') }` }</li>
        <li>{ `${ I18n.t('documents.form.require_convert_to_pdf') }` }</li>
      </ul>
    )
  }, [])

  const drawLabelInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _document?.errors?.label || [] }>
          <Label
            for={ inputId(formName, 'label') }
          >
            { I18n.t(`activerecord.attributes.document.label`) }
          </Label>
          { drawTooltipHelp(I18n.t('documents.form.label_tooltip')) }

          <Input
            id={ inputId(formName, 'label') }
            name={ inputName(formName, 'label') }
            invalid={ _.has(_document?.errors, 'label') }
            value={ _document?.label || '' }
            onChange={ e => handleDocument(e, 'label') }
            disabled={ isPersisted }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // End Label

  // Start File
  const drawFileInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _document?.errors?.file || [] }>
          <Label
            for={ inputId(formName, 'file') }
            className="required"
          >
            { I18n.t(`activerecord.attributes.document.file`) }
          </Label>
          { drawTooltipDocumentFile() }
          <Input
            type='file'
            id={ inputId(formName, 'file') }
            name={ inputName(formName, 'file') }
            accept={ _.map(data?.allowedFileFormats, 'content_type') }
            invalid={ _.has(_document?.errors, 'file') }
            onChange={ e => handleDocument(e, 'file') }
            disabled={ isPersisted }
          />
        </FieldError>
        <DocumentPreview
          file={ _document?.file }
          allowedContentTypes={ _.map(data?.allowedFileFormats, 'content_type') }
        />
        { drawDocumentFileInfo }
      </FormGroup>
    )
  }
  // End File

  // Start keep format
  const drawKeepFormatSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ _document?.errors?.keep_format || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'keep_format') }
              id={ inputId(formName, 'keep_format') }
              invalid={ _.has(_document?.errors, 'keep_format') }
              onChange={ e => handleDocument(e, 'keep_format') }
              checked={ _document?.keep_format || false }
              value={ '1' }
              style={{ display: 'contents' }}
              disabled={ _document?.require_signers }
            />
            <Label
              className='custom-control-label'
              for={ inputId(formName, 'keep_format') }
            >
              { I18n.t(`activerecord.attributes.document.keep_format`) }
              { drawTooltipHelp(I18n.t('documents.new.keep_format_help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // End keep format

  // Start Requires Expire
  const drawRequiresExpireSwitchInput = () => {
    return(
      <FormGroup>
        <div className="custom-switch">
          <FieldError errors={ _document?.errors?.requires_expire || [] }>
            <Input
              className='custom-control-input'
              type="checkbox"
              id={ inputId(formName, 'requires_expire') }
              name={ inputName(formName, 'requires_expire') }
              invalid={ _.has(_document?.errors, 'requires_expire') }
              onChange={ e => handleDocument(e, 'requires_expire') }
              checked={ _document?.requires_expire || false }
              value={ '1' }
              style={{ display: 'contents' }}
            />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'requires_expire') }
            >
              { I18n.t(`activerecord.attributes.document.requires_expire`) }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.expires_date_help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // End Requires Expire

  // Start Expires at
  const drawExpiresAtInput = () => {
    if(_document.requires_expire){
      return(
        <FormGroup>
          <FieldError errors={ _document?.errors?.expires_at || [] }>
            <Input
              type='date'
              id={ inputId(formName, 'expires_at') }
              name={ inputName(formName, 'expires_at') }
              invalid={ _.has(_document?.errors, 'expires_at') }
              onChange={ e => handleDocument(e, 'expires_at') }
              value={ _document?.expires_at || '' }
            />

          </FieldError>
        </FormGroup>
      )
    }
  }
  // End Expires at

  // Start Handle
  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('documents.form.confirm.title'),
      html: I18n.t('documents.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        handleConfirm();
      }
    })
  }

  const handleConfirm = () => {
    let formData = getFormData();

    let requestParams = defaultRequestParams;

    if(_document.id){
      requestParams = _.merge(requestParams, { id: _document.id});
      updateDocument(requestParams, formData, response => {
        if(response.status == 200){
          handleSubmitRedirect(response);
        } else {
          setDocument(response.data);
        }
      })
    } else {
      createDocument(requestParams, formData, response => {
        if(response.status == 201){
          handleSubmitRedirect(response)
        } else {
          setDocument(response.data);
        }
      })
    }
  }

  const handleSubmitRedirect = (response) => {
    let checkListTaskId = props?.data?.checkListTaskId;

    let requestParams = defaultRequestParams;

    if(response.status == 201 && checkListTaskId){
      window.location = showCheckListTaskPath(checkListTaskId);
    } else {

      requestParams = { ... requestParams,
        id: response?.data?.hashid,
        _options: true,
        format: ''
      }
      window.location = showDocumentPath(requestParams)
    }
  }

  const handleDocument = (event, key) => {
    let documentChanges = {}

    let value = event?.target?.value;
    let type = event?.target?.type;

    if(type == 'file'){
      value = event?.target?.files[0]
      setSelectedFile(value)
    }

    if(type == 'checkbox'){
      value = event?.target?.checked;
    }

    documentChanges = {
      ... documentChanges,
      ... onChangeDocumentHelper(_document, key, value, data?.documentTypes )
    }

    documentChanges[key] = value;

    setDocument(prevState => {
      return { ... prevState, ... documentChanges }
    })
  }

  const handleSigners = _signers => {
    handleDocument({ target: { value: _signers }}, 'signers');
  }
  // End Handle

  // Start Signers
  const drawSignerFormList = () => {
    if(_document.require_signers){
      return(
        <div className="card card-outline card-primary">
          <div className="card-header">
            { I18n.t('activerecord.models.signer.other') }
          </div>
          <div className="card-body">
            <SignerFormList
              signers={ _document?.signers || [] }
              signersCount={ signersCount }
              formName={ `${ formName }[signer_attributes]`}
              setResource={ setDocument }
            />
          </div>
        </div>
      )
    }
  }
  // End Signers

  // Start SignerGroups
  const drawSignerGroupFormList = () => {
    if(_document.require_signers && currentEntityType?.enabled_signer_groups_module){
      return(
        <div className="card card-outline card-primary">
          <div className="card-header">
            { I18n.t('activerecord.models.signer_group.one') }
          </div>
          <div className="card-body">
            <SignerGroupFormList
              signerGroups={ _document?.signer_groups || [] }
              formName={ `${ formName }[signer_groups_attributes]`}
              signersCount={ signersCount }
              setResource={ setDocument }
            />
          </div>
        </div>
      )
    }
  }
  // End SignerGroups

  const isPersisted = useMemo(() => {
    return !!_document.id;
  }, [])

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, _document?.id || '');
    formData.append(`${ formName }[entity_id]`, _document?.entity_id || '');
    formData.append(`${ formName }[label]`, _document?.label || '');
    formData.append(`${ formName }[document_type_id]`, _document?.document_type_id || '');
    formData.append(`${ formName }[requires_expire]`, _document?.requires_expire || false);
    formData.append(`${ formName }[expires_at]`, _document?.expires_at || '');

    if(selectedFile){
      formData.append(`${ formName }[file]`, selectedFile || '');
    }

    formData.append(`${ formName }[require_signers]`, _document?.require_signers || false);
    formData.append(`${ formName }[require_fao]`, _document?.require_fao || false);
    formData.append(`${ formName }[require_signers_order]`, _document?.require_signers_order || false);
    formData.append(`${ formName }[require_signature_deadline]`, _document?.require_signature_deadline || false);
    formData.append(`${ formName }[signature_deadline_at]`, _document?.signature_deadline_at || '');
    formData.append(`${ formName }[generate_viewer_table]`, _document?.generate_viewer_table || false);
    formData.append(`${ formName }[keep_format]`, _document?.keep_format || false);

    if(data.checkListTaskId){
      formData.append(`${ formName }[check_list_task_ids][]`, data.checkListTaskId);
    }

    _.each(_document.signers, function(signer, index){
      formData.append(`${ formName }[signers_attributes][${ index }][id]`, signer?.id || '');
      formData.append(`${ formName }[signers_attributes][${ index }][email]`, signer?.email || '');
      formData.append(`${ formName }[signers_attributes][${ index }][name]`, signer?.name || '');
      formData.append(`${ formName }[signers_attributes][${ index }][role]`, signer?.role || '');
      formData.append(`${ formName }[signers_attributes][${ index }][order]`, signer?.order || '0');
      formData.append(`${ formName }[signers_attributes][${ index }][employee_sign]`, signer?.employee_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][member_sign]`, signer?.member_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][email_notification_sign]`, signer?.email_notification_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][company_sign]`, signer?.company_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][company_email]`, signer?.company_email || '');
      formData.append(`${ formName }[signers_attributes][${ index }][_destroy]`, signer?._destroy || false);
      formData.append(`${ formName }[signers_attributes][${ index }][identification_number]`, signer?.identification_number || '');
      formData.append(`${ formName }[signers_attributes][${ index }][first_surname]`, signer?.first_surname || '');
      formData.append(`${ formName }[signers_attributes][${ index }][last_surname]`, signer?.last_surname || '');
      formData.append(`${ formName }[signers_attributes][${ index }][is_viewer]`, signer?.is_viewer || false);

      if(context == 'abstract_bulk'){
        formData.append(`${ formName }[signers_attributes][${ index }][custom_field_id]`, signer?.custom_field_id || '');
      }
    })

    _.each(_document.signer_groups, function(group, index){
      let _formName = `${ formName }[signer_groups_attributes][${ index }]`;
      formData.append(`${ _formName }[id]`, group?.id || '');
      formData.append(`${ _formName }[name]`, group?.name || '');
      formData.append(`${ _formName }[order]`, group?.order || '0');
      formData.append(`${ _formName }[require_minimun_signers]`, group?.require_minimun_signers || false);
      formData.append(`${ _formName }[minimun_signers]`, group?.minimun_signers || '0');
      formData.append(`${ _formName }[_destroy]`, group?._destroy || false);

      _.each(group.signers, function(signer, index){
        formData.append(`${ _formName }[signers_attributes][${ index }][id]`, signer?.id || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][email]`, signer?.email || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][name]`, signer?.name || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][role]`, signer?.role || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][employee_sign]`, signer?.employee_sign || false);
        formData.append(`${ _formName }[signers_attributes][${ index }][member_sign]`, signer?.member_sign || false);
        formData.append(`${ _formName }[signers_attributes][${ index }][email_notification_sign]`, signer?.email_notification_sign || false);
        formData.append(`${ _formName }[signers_attributes][${ index }][_destroy]`, signer?._destroy || false);
        formData.append(`${ _formName }[signers_attributes][${ index }][identification_number]`, signer?.identification_number || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][first_surname]`, signer?.first_surname || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][last_surname]`, signer?.last_surname || '');
        formData.append(`${ _formName }[signers_attributes][${ index }][is_viewer]`, signer?.is_viewer || false);
      })
    })

    return formData;
  }

  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <DocumentSignerConfigContext.Provider value={ documentSignerConfigContextValue }>
        <div className="row">
          <div className="col-12">
            <div className="card card-outline card-primary">
              <div className="card-header">
                { I18n.t('documents.show.details') }
              </div>
              <div className="card-body">
                { drawEntitySelectInput() }
                { drawDocumentTypeSelectInput() }
                { drawLabelInput() }
                { drawFileInput() }
                { drawKeepFormatSwitchInput() }
                { drawRequiresExpireSwitchInput() }
                { drawExpiresAtInput() }

                <DocumentSignerConfigAttributes
                  handleResource={ handleDocument }
                  resource={ _document }
                />
              </div>
            </div>

            { drawSignerFormList() }
            { drawSignerGroupFormList() }

            <FormGroup className="text-end">
              <a
                href={ indexDocumentsPath }
                className='btn btn-default'
              >
                { I18n.t('actions.back') }
              </a>

              <Button
                color='success'
                className='ml-2'
                onClick={ e => handleConfirmSubmit() }
              >
                { I18n.t('actions.save') }
              </Button>
            </FormGroup>
          </div>
        </div>
      </DocumentSignerConfigContext.Provider>
    </DocumentFormContext.Provider>
  )
}

export default DocumentAttributes;
