import React, { useEffect, useState, useCallback } from 'react';
import LaboralBulkLoadHiringFileInput from './file_input';
import LaboralBulkLoadHiringListHidden from './list_hidden';
import LaboralBulkLoadHiringTableErrors from './table_errors';
import { FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import I18n from 'i18n-js';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import swal from "sweetalert2";
import { options } from '../../../../helper/pagination';
import { defaultTableHeaderStyle } from "../../../../helper/bootstrap-table/helper";
import * as moment from 'moment';
import _ from 'lodash';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { formatToCurrency } from "../../../../helper/form";

import { fetchCustomFieldValue, isAuthorizedCustomFieldToUser } from "../../../../helper/custom_field/helper";

const LaboralBulkLoadHiringForm = ({companies, employees, defaultHeaders, customFieldsHeaders, currentUser}) => {
  const formName = 'laboral_bulk_load_hiring'
  const [hiringBulkErrors, setHiringBulkErrors] = useState([])
  const [hiringBulk, setHiringBulk] = useState([])
  const [employeesSelect, setEmployeesSelect] = useState([])
  const [companiesSelect, setCompaniesSelect] = useState([])
  const [dataHidden, setDataHidden] = useState([])
  const [updateHiring, setUpdateHiring] = useState(false)
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)
  const _controldoc_id = 'controldoc_id'
  const _headers_concat = _.concat(defaultHeaders, _.map(customFieldsHeaders, 'code'), _controldoc_id)
  const [statesFilters, setStatesFilters] = useState([]);

  // se excluye el primer elemento ya que ahora se posiciona al final del arreglo
  const headers = _.slice(_headers_concat, 1, _headers_concat.length)

  useEffect(()=>{
    setDataHidden(hiringBulk)
  },[hiringBulk])

  useEffect(()=>{
    if(updateHiring){
      setHiringBulk(hiringBulk)
      setUpdateHiring(false)
    }
  },[updateHiring])

  useEffect(() => {
    _.isEmpty(statesFilters) ? addStatesFilters() : null
  }, [statesFilters]);

  const addStatesFilters = () => {
    let _state_employee = { "field": "employee_id", "value": false };
    let _state_company = { "field": "company_id", "value": false };
    let _state_hiring_identifier = { "field": "hiring_id", "value": false };
    let _state_start_date = { "field": "start_date", "value": false };
    let _state_end_date = { "field": "end_date", "value": false };
    let _states_filter = _.map(customFieldsHeaders, function (_field) {
      return { "field": _field.code, "value": false};
    })
    setStatesFilters(_.concat(_state_employee, _state_company, _state_hiring_identifier, _state_start_date, _state_end_date, _states_filter));
  }

  const headerTitleCustom = (column, colIndex, { filterElement }) => {
    let _filter = _.find(statesFilters, { "field": column.dataField });
    return (
      <div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">{column.text}</p>
          {
            !column.filterDisabled &&
            <React.Fragment>
              <div>
                <button onClick={e => handleAddStateFilter(column, filterElement)} className="btn btn-link m-0 p-0" type="button">
                  <i className={_filter?.value ? "far fa-times-circle mt-1 fs-6 text-white" : "fas fa-search mt-1 fs-6 text-white"}></i>
                </button>
              </div>
            </React.Fragment>
          }
        </div>
        <div className="row">
          {drawInputSearch(column, filterElement)}
        </div>
      </div>
    )
  }

  const handleAddStateFilter = (column, filterElement) => {
    let _changeStateFilter = statesFilters;
    let _field = _.remove(_changeStateFilter, { field: column.dataField })
    _field[0].value = !_field[0].value;
    if (!_field[0].value) {
      filterElement.props.filterState.filterVal = ""
    }
    setStatesFilters(_.concat(_changeStateFilter, _field));
  }

  const drawInputSearch = (column, filterElement) => {
    let _field = _.find(statesFilters, { field: column.dataField });
    if (_field?.value) {
      return (
        <div >
          {filterElement}
        </div>
      )
    }
  }

  const onChangeCellValues = (e,index, key, customField) =>{
    let value = e?.target?.value

    if(customField.data_type == 'currency'){
      value = formatToCurrency(value)
    }

    if(customField.data_type == 'date'){
      // se convierte a formato DD/MM/YYYY para que al cargar los campos
      // los vuelva a transformar a YYYY-MM-DD y asi no se pierde el formato para mostrarlo
      value = moment(value, "YYYY-MM-DD").locale('es').format('DD/MM/YYYY')
    }

    hiringBulk[index][key] = value
    setUpdateHiring(true)
  }

  const customFilterEmployeeName = (filterVal, data) => {
    if (filterVal) {
      return _.filter(data, function (hiring) {
        return _.includes(
          _.toUpper(hiring.employee_id),
          _.toUpper(filterVal)
        )
          ? hiring
          : null;
      })
    }
    return data;
  }

  const customFieldSelectOptions = useCallback((listOptions) => {
    return listOptions.map((option) => {
      return (
        <option
          key={ _.uniqueId("_custom_fields") }
          value={ option }
        >
          { option }
        </option>
      );
    });
  },[])

  const customFieldSelectInput = (value, index, row, custom) => {
    let inputClass = custom.data_type === 'currency' ? 'currency-field' : '';

    if(custom.data_type === "list"){
      return (
        <Input
          type="select"
          key={ _.uniqueId("_custom_fields") }
          value={ value }
          onChange={ e => onChangeCellValues(e, index, custom.code) }
          disabled= { !isAuthorizedCustomFieldToUser(custom, currentUser) }
        >
          <option key={ _.uniqueId("_custom_fields") } value=""></option>
          { customFieldSelectOptions(custom.list_options) }
        </Input>
      );
    }else{
      return (
        <Input
          key={ _.uniqueId(`${custom.code}_input_`) }
          type={ custom.data_type }
          id={ `${row.row}-${custom.code}` }
          className={ inputClass }
          onBlur={ e => onChangeCellValues(e,index, custom.code, custom) }
          defaultValue={ value }
          disabled= { !isAuthorizedCustomFieldToUser(custom, currentUser) }
        />
      );
    }
  }

  const getCustomFields= (customFieldsHeaders)=>{
    let _custom_fields = _.map(customFieldsHeaders)
    let items = []
    let btn_column = [{
      dataField: "action",
      text: "Acciones",
      editable: false,
      headerStyle: {...defaultTableHeaderStyle, width: "100px"},
      formatter: (cellContent, row) => {
        return (
          <div className='d-flex align-items-center justify-content-center'>
            <Button
              color="danger"
              outline
              className="border-0"
              onClick={ (e) => handleDestroyHiring(row.row) }
            >
              <i className="fas fa-trash-alt" />
            </Button>
            { iconNewHiring(row)}
          </div>
        );
      }
    }]

    _.each(_custom_fields, function(custom){
      let item = {
        dataField: custom.code,
        text: custom.code,
        headerStyle: defaultTableHeaderStyle,
        filterDisabled: !isAuthorizedCustomFieldToUser(custom, currentUser),
        formatter : function(value,row,index) {
          const formattedValue = custom.data_type === "date"
            ? moment(value, "DD/MM/YYYY").locale('es').format('YYYY-MM-DD')
            : value;

          return customFieldSelectInput(formattedValue, index, row, custom)
        },
        filter: textFilter({
          placeholder: `Ingrese ${_.lowerCase(custom.code)}`
        }),
        headerFormatter: headerTitleCustom
      }
      items.push(item)
    })

    return _.concat(items, btn_column);
  }

  const firstPartColumns = [
    {
      dataField: 'controldoc_id',
      text: 'ControldocId',
      headerStyle: {display : 'none'},
      style: {display : 'none'},
      formatter : function(value,row,index) {
        return (<Input key={_.uniqueId("controldoc_id_input_")} type="hidden" defaultValue={ value } readOnly/>);
      }
    }, {
      dataField: 'hiring_id',
      text: 'Identificador',
      headerStyle: defaultTableHeaderStyle,
      formatter : function(value,row,index) {
        return (
          <Input
            key={_.uniqueId("hiring_id_input_")}
            type="text"
            id={`${row.row}-hiring_id`}
            onBlur={ e =>handleValues(e,index,row)}
            defaultValue={ value }
          />
        );
      },
      filter: textFilter({
        placeholder: "Ingrese identificador"
      }),
      headerFormatter: headerTitleCustom
    }, {
      dataField: 'employee_id',
      text: 'Trabajador',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input
            key={_.uniqueId("employee_id_input_")}
            type="select"
            id={`${row.row}-employee_id`}
            placeholder="-- Seleccionar Trabajador --"
            className='form-control-border'
            onChange={ e => handleValues(e,index,row) }
            defaultValue={ value.split(" /")[0] }
          >
            { drawValueEmployeeSelect() }
          </Input>
        )
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        onFilter: customFilterEmployeeName,
        placeholder: "Ingrese Trabajador"
      })
    }, {
      dataField: 'company_id',
      text: 'Empresa',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input
            key={_.uniqueId("company_id_input_")}
            type="select"
            id={`${row.row}-company_id`}
            placeholder="-- Seleccionar Empresa --"
            onChange={ e =>handleValues(e,index,row)}
            className='form-control-border'
            defaultValue={ value.split(" /")[0]  }
          >
            { drawValueCompanySelect() }
          </Input>
        )
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        placeholder: "Ingrese Empresa"
      })
    }, {
      dataField: 'start_date',
      text: 'Fecha Inicio',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input type="date"
            key={_.uniqueId("start_date_input_")}
            id={`${row.row}-start_date`}
            className='form-control-border'
            onChange={ e =>handleValues(e,index,row)}
            defaultValue={ value }
          />
        )
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        placeholder: "Ingrese fecha de inicio"
      })
    }, {
      dataField: 'end_date',
      text: 'Fecha Termino',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input type="date"
            key={_.uniqueId("end_date_input_")}
            id={`${row.row}-end_date`}
            className='form-control-border'
            onChange={ e =>handleValues(e,index,row)}
            defaultValue={ value }
          />
        )
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        placeholder: "Ingrese fecha de término"
      })
    }
  ];

  const columns = _.concat(firstPartColumns, getCustomFields(customFieldsHeaders))

  const iconNewHiring = (row) =>{
    if(row.controldoc_id == ""){
      return(
        <div className="p-1 fs-5 ml-2">
         <span id="tooltipTonNewHiring">
            <i className="fas fa-folder-plus text-info"></i>
          </span>
          <UncontrolledTooltip key={  _.uniqueId(row.row) } placement="bottom" target="tooltipTonNewHiring">
            Nueva contratación
          </UncontrolledTooltip>
        </div>
      )
    }
  }

  const drawHiringBulk = () => {
    if( hiringBulk?.length > 0 ){
      return(
        <div className="row">
          <h2 className="card-title mb-2">Registros ({ hiringBulk?.length }):</h2>
          <BootstrapTable
            id="table-primary"
            keyField='row'
            data={ hiringBulk }
            columns={ columns }
            wrapperClasses="table-responsive height-600"
            pagination={ paginationFactory(options) }
            hover
            noDataIndication="No existen registros"
            filter={filterFactory()}
          />
        </div>
      )
    }
  }

  const drawValueEmployeeSelect = () => {

    return _.map(employeesSelect, function(employee){
      return (
        <option
          key={ _.uniqueId(`employee_option_`) }
          value={ employee.identifier }
          label={ employee.name }
        >
          { employee.name }
        </option>
      )
    })
  }

  const drawValueCompanySelect = () => {
    return _.map(companiesSelect, function(company){
      return (
        <option
          key={ _.uniqueId(`company_option_`) }
          value={ company.identifier }
          label={ company.name }
        >
          { company.name }
        </option>
      )
    })
  }

  const validateItem = ( item, row ) =>{
    return (
      item.row === row.row &&
      item.employee_id != "" &&
      item.company_id != "" &&
      item.start_date != "" &&
      moment(item.start_date, 'YYYY-MM-DD',true).isValid() &&
      (
        item.end_date == "" ||
        moment(item.end_date, 'YYYY-MM-DD',true).isValid() &&
        item.start_date < item.end_date
      )
    )
  }

  const handleValidateHiringId = (row) => {
    let addNewData = [];
    let filteredArray = [];

    _.each(hiringBulkErrors, function(item){
      if(validateItem(item, row) && !isExistHiringId(item["hiring_id"]) || item["hiring_id"] == "" && validateItem(item, row) ){
        addNewData.push(item);
        swal.fire({
          icon: 'success',
          title: 'Genial',
          text: 'Fila corregida'
        })
        return true;
      } else if(validateItem(item, row)){
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El identificador de la contratación ya se encuentra en uso',
        })
      } else if( item.row === row.row){
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Campos vacios verifique los datos',
        })
      }
      filteredArray.push(item)
    })
    setHiringBulkErrors(filteredArray);
    if(addNewData.length > 0){
      let _hiringBulk = [...hiringBulk]
      _hiringBulk = _.concat(addNewData,hiringBulk)
      setHiringBulk(_hiringBulk);
    }
  }

  const handleDestroyHiring = (row, direct = false) => {
    if(!direct){
      swal.fire({
        title: 'Eliminar fila',
        text: "Esta seguro de eliminar la fila",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'swal2-confirm btn btn-success ml-3',
          cancelButton: 'swal2-cancel btn btn-secondary'
        },
        buttonsStyling: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
        cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let _hiringBulk = [...hiringBulk]
          _.remove(_hiringBulk, item => { return item.row == row })
          setHiringBulk(_hiringBulk);
          swal.fire(
            'Eliminado!',
            'La fila ha sido elimina correctamente',
            'success'
          )
        }
      })
    }else{
      let _hiringBulk = [...hiringBulk]
      _.remove(_hiringBulk, item=>{return item.row == row})
      setHiringBulk(_hiringBulk);
    }
  };

  const isKeyInput = (key, e)=>{
    return(
      key == "hiring_id" ||
      key == "start_date" ||
      key == "end_date"
    )
  }

  const isKeySelector = (key)=>{
    return(
      key == "employee_id" ||
      key == "company_id"
    )
  }

  const verifyDates=( value, key, row)=>{
    return (
      key == "start_date" && value == "" ||
      key == "start_date" && row.end_date != "" && (value > row.end_date) ||
      key == "end_date" && (row.start_date > value)
    )
  }

  const setValueIfExistHiringId=(e, index, key, row )=>{
    let value = e.target.value;
    if( verifyDates(value, key, row)){
      handleDestroyHiring(hiringBulk[index].row, true)
      setHiringBulkErrors(_.concat([hiringBulk[index]],hiringBulkErrors))
    }
    hiringBulk[index][key] = value;
  }

  const handleValues = ( e, index, row,error = false ) => {
    document.getElementById(e?.target?.id).value = e?.target?.value;
    let  key = e.target.id.split("-")[1];

    if( isKeyInput(key , e) && error ){
      hiringBulkErrors[index][key] = e.target.value;
    }else if( isKeyInput(key , e) && !error ){
      setValueIfExistHiringId(e, index, key, row)
    }else if( isKeySelector(key) && !error ){
      hiringBulk[index][key] = `${e.target.value} / ${e.target.selectedOptions[0].text}`;
    }else if( isKeySelector(key) && error ){
      hiringBulkErrors[index][key] = `${e.target.value} / ${e.target.selectedOptions[0].text}`;
    }else{
      hiringBulk[index][key] = e.target.value;
    }
    setUpdateHiring(true)
  }

  const isExistHiringId= (value, row = false) =>{
    if(row){
      let fil = _.filter(dataHidden, item=>{ return item.row != row.row})
      return !!_.find(fil, {"hiring_id": value})
    }else{
      return !!_.find(dataHidden, {"hiring_id": value})
    }
  }

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    swal.fire({
      title: 'Realizar carga masiva de contrataciones',
      html: '¿Estás seguro de realizar la carga masiva?',
      cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
      confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
      reverseButtons: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-success ml-3',
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false,
    }).then( result => {
      if(result.isConfirmed){
        let _form = document.getElementById("new_laboral_bulk_load_hiring")
        setDisabledSubmitButton(true)
        _form.submit()
      }
    })
  }

  const drawTextSubmitButton = () => {
    if(disabledSubmitButton){
      return I18n.t('actions.saving')
    } else {
      return I18n.t('actions.save')
    }
  }

  const drawBtnSubmit = () => {
    if( hiringBulk?.length > 0 ){
      return(
        <button
          type='submit'
          className='btn btn-success ml-2'
          onClick={ e => handleConfirmSubmit(e) }
          disabled={ disabledSubmitButton }
        >
          { drawTextSubmitButton() }
        </button>
      )
    }
  }

  return(
    <div className="row">
      <form action={Routes.laboral_bulk_load_hirings_path()} encType="multipart/form-data" method="post" id="new_laboral_bulk_load_hiring">
        <input type="hidden" name="authenticity_token" value={window.compliance.token} />
        <div className="col-12">
          <LaboralBulkLoadHiringFileInput
            headers={ headers }
            setHiringBulk={ setHiringBulk }
            customFieldsHeaders= { customFieldsHeaders || [] }
            setHiringBulkErrors={ setHiringBulkErrors }
            formName={ formName }
            setCompaniesSelect={ setCompaniesSelect }
            setEmployeesSelect={ setEmployeesSelect }
          />
        </div>
        <div className="col-12">
          <LaboralBulkLoadHiringListHidden
            customFields={ customFieldsHeaders || [] }
            currentUser={ currentUser }
            formName={ formName}
            dataHidden={ dataHidden }
          />
        </div>
        <div className="col-12">
          <LaboralBulkLoadHiringTableErrors
            customFieldsHeaders= { _.map(customFieldsHeaders) }
            iconNewHiring={ iconNewHiring }
            handleValues={ handleValues }
            hiringBulk={ hiringBulk }
            isExistHiringId={ isExistHiringId }
            handleValidateHiringId={ handleValidateHiringId }
            drawValueEmployeeSelect= { drawValueEmployeeSelect }
            drawValueCompanySelect= { drawValueCompanySelect }
            setHiringBulkErrors={ setHiringBulkErrors }
            hiringBulkErrors={ hiringBulkErrors }
          />
        </div>
        <div className="col-12">
          { drawHiringBulk() }
        </div>
        <FormGroup className='text-end'>
          <a
            href={ Routes.laboral_bulk_load_hirings_path() }
            className='btn btn-default'
          >
            { I18n.t('actions.back') }
          </a>
          { drawBtnSubmit() }
        </FormGroup>
      </form>
    </div>
  )
}

export default LaboralBulkLoadHiringForm;
