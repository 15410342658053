import React, {
  useState,
  useEffect,
  useMemo
} from 'react';

import {
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import axios from 'axios';

import WorkflowRequirementStageModalForm from './modal_form';

const aasmStateEventConfig = (event) => {
  if(event == 'to_proceeded'){
    return { style: 'success', icon: 'check-circle' }
  } else if (event == 'to_completed'){
    return { style: 'success', icon: 'clipboard-check' }
  } else if (event == 'to_returned'){
    return { style: 'warning', icon: 'undo' }
  } else if (event == 'to_rejected'){
    return { style: 'danger', icon: 'ban' }
  }
}

const optionsForModule = props => {
  return {
    karinLaw: {
      defaultRequestParams: {
        id: props?.requirementStage?.hashid
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid
      },
      routes: {
        showRequirementPath: Routes.karin_law_workflow_requirement_path,
        updateRequirementStagePath: Routes.karin_law_workflow_requirement_stage_path
      }
    },
    portalKarinLaw: {
      defaultRequestParams: {
        id: props?.requirementStage?.hashid,
        tenant: props?.configuration?.tenant
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid,
        tenant: props?.configuration?.tenant
      },
      routes: {
        showRequirementPath: Routes.portal_karin_law_workflow_requirement_path,
        updateRequirementStagePath: Routes.portal_karin_law_workflow_requirement_stage_path
      }
    },
    abstract: {
      defaultRequestParams: {
        id: props?.requirementStage.hashid,
        current_entity_type_id: props?.currentEntityType?.hashid
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid,
        current_entity_type_id: props?.currentEntityType?.hashid
      },
      routes: {
        showRequirementPath: Routes.abstract_workflow_requirement_path,
        updateRequirementStagePath: Routes.abstract_workflow_requirement_stage_path
      }
    },
    portalAbstract: {
      defaultRequestParams: {
        id: props?.requirementStage?.hashid,
        tenant: props?.configuration?.tenant
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid,
        tenant: props?.configuration?.tenant
      },
      routes: {
        showRequirementPath: Routes.portal_abstract_workflow_requirement_path,
        updateRequirementStagePath: Routes.portal_abstract_workflow_requirement_stage_path
      }
    },
    laboral: {
      defaultRequestParams: {
        id: props?.requirementStage.hashid
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid
      },
      routes: {
        showRequirementPath: Routes.laboral_workflow_requirement_path,
        updateRequirementStagePath: Routes.laboral_workflow_requirement_stage_path
      }
    },
    portalLaboral: {
      defaultRequestParams: {
        id: props?.requirementStage?.hashid,
        tenant: props?.configuration?.tenant
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid,
        tenant: props?.configuration?.tenant
      },
      routes: {
        showRequirementPath: Routes.portal_laboral_workflow_requirement_path,
        updateRequirementStagePath: Routes.portal_laboral_workflow_requirement_stage_path
      }
    },
    economicCrime: {
      defaultRequestParams: {
        id: props?.requirementStage?.hashid,
      },
      defaultRequestRequirementParams: {
        id: props?.requirementStage?.requirement?.hashid
      },
      routes: {
        showRequirementPath: Routes.economic_crime_workflow_requirement_path,
        updateRequirementStagePath: Routes.economic_crime_workflow_requirement_stage_path
      }
    },
  }
}

const WorkflowRequirementStageToolbarEvent = props => {
  const {
    currentUser,
    currentCustomer,
    policies,
    data,
    policies: {
      canEdit: policyCanEdit
    },
  } = props;

  const [requirementStage, setRequirementStage] = useState(props?.requirementStage);

  const isPortalContext = useMemo(() => {
    return props?.configuration?.context == 'portal';
  })

  const isKarinLaw = useMemo(() => {
    return requirementStage.module_type == 'KarinLaw::Workflow::RequirementStage'
  })

  const isAbstract = useMemo(() => {
    return requirementStage.module_type == 'Abstract::Workflow::RequirementStage'
  })

  const isEconomicCrime = useMemo(() => {
    return requirementStage.module_type == 'EconomicCrime::Workflow::RequirementStage'
  })

  const isLaboral = useMemo(() => {
    return requirementStage.module_type == 'Laboral::Workflow::RequirementStage';
  }, [])

  const currentOptionModule = useMemo(() => {
    let key = undefined;

    if(isAbstract && isPortalContext){
      key = 'portalAbstract'
    } else if(isAbstract){
      key = 'abstract'
    } else if (isKarinLaw && isPortalContext){
      key = 'portalKarinLaw'
    } else if(isKarinLaw){
      key = 'karinLaw'
    } else if(isEconomicCrime){
      key = 'economicCrime'
    } else if(isLaboral && isPortalContext){
      key = 'portalLaboral'
    } else if(isLaboral){
      key = 'laboral'
    }

    return optionsForModule(props)[key]
  }, [])

  const {
    defaultRequestParams,
    defaultRequestRequirementParams,
    routes: {
      showRequirementPath,
      updateRequirementStagePath
    }
  } = currentOptionModule;


  const updateRequirementStageService = (requestParams, formData, callback) => {
    var promise = axios({
      method: 'put',
      url: updateRequirementStagePath({ ...{ ...requestParams }, format: 'json', _options: true }),
      data: formData,
      headers: {
        "X-CSRF-TOKEN": window.compliance.token
      },
    });

    promise
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error.response);
      });
  }

  const onSuccessSubmit = () => {
    window.location = showRequirementPath({ ...defaultRequestRequirementParams, _options: true })
  }

  const onChangeRequirementAasmEvent = (aasmEvent) => {
    setRequirementStage(prevState => {
      return { ... prevState, aasm_state_event: aasmEvent}
    })
  }

  const buttonEventActions = () => {
    if(requirementStage?.events?.permitted && policyCanEdit){
      return(
        _.map(requirementStage.events.permitted, permittedEvent => {
          const config = aasmStateEventConfig(permittedEvent?.event)
          return(
            <Button
              key={ `EventButton-${ permittedEvent.event }` }
              className='ml-1'
              outline
              color={ config?.style }
              size={ 'sm' }
              onClick={ e => onChangeRequirementAasmEvent(permittedEvent.event) }
            >
              <i className={ `fas fa-${ config?.icon }` }></i>
              &nbsp;
              { permittedEvent?.human_name }
            </Button>
          )
        })
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12 text-end">
        { buttonEventActions() }
        <WorkflowRequirementStageModalForm
          requirementStage={ requirementStage }
          configuration={{
            defaultRequestParams: defaultRequestParams
          }}
          callbacks={{
            onSuccessSubmit: onSuccessSubmit,
            onChangeRequirementAasmEvent: onChangeRequirementAasmEvent
          }}
          services={{
            updateRequirementStage: updateRequirementStageService
          }}
        />
      </div>
    </div>
  )
}

export default WorkflowRequirementStageToolbarEvent;
