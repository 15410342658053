import React, {
  useState,
  useMemo,
  useRef
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../helper/form';

import {
  bytesToSize
} from '../../helper/common';

import {
  fileItemIsRequired
} from '../requirement_stage/helper';

const WorkflowRequirementStageFormItemFileAttributes = props => {
  const {
    requirementStage,
    fileItem,
    configuration: {
      formName, defaultRequestParams
    },
    callbacks: {
      onChangeRequirementStage
    }
  } = props;

  const fileInputRef = useRef(null);

  const [fileData, setFileData] = useState({filename: '', size: 0 })
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");

  const showShortlistPath = props?.actions?.showShortlistPath || ""

  const [uploadNewFile, setUploadNewFile] = useState(!requirementStage?.prev_requirement_stage_id)

  const previewPdfUrlShortlist = useMemo(() => {
    let hashid = requirementStage?.shortlist_assessment_load?.hashid

    return hashid && _.isFunction(showShortlistPath) ? showShortlistPath({...{...defaultRequestParams, id: hashid, format: 'pdf', _options: true }}) : false

  }, [requirementStage?.shortlist_assessment_load])

  const isRequired = useMemo(() => {
    return fileItemIsRequired(fileItem)
  }, [fileItem.is_required])

  const onUpdateFileItem = (newFileItem) => {
    let outputForm = requirementStage.output_form;

    const fileItemIndex = _.findIndex(outputForm.file_items, item => {
      return item.item.id == newFileItem.item.id
    });

    outputForm.file_items[fileItemIndex] = newFileItem;

    onChangeRequirementStage(
      { target: { value: outputForm }}, 'output_form'
    )
  }

  const onChangeFileItem = (event) => {
    const value = event.target.files[0];

    let _fileItem = { ... fileItem };
    _fileItem['file'] = value;

    onUpdateFileItem(_fileItem);
  }

  const helpingText = () => {
    if(fileItem.helping_text){
      return(
        <span className='text-muted small'>{ fileItem.helping_text }</span>
      )
    }
  }

  const uploadNewFileOrUseEntryFileInput = () => {
    if (uploadNewFile) {
      return (
        <>
          <Input
            type={'file'}
            ref={fileInputRef}
            required={isRequired}
            onChange={handleFileChange}
            invalid={_.has(fileItem.errors, 'file')}
            id={inputId(formName, 'value')}
            name={inputName(formName, 'value')}
            style={{ display: "none" }}
          />
        </>
      )
    } else {
      return (
        <>
          <label className="text-muted" htmlFor="">Seleccionar desde archivo de entrada</label>
          <Select
            options={inputFileSelectOptions}
            onChange={event => onChangeFileReferenceTo(event)}
            value={inputFileSelectedOption}
            placeholder="Seleccionar un archivo"
          />
        </>
      )
    }
  }

  // Función para simular la subida de archivo
  const simulateFileUpload = () => {
    let uploadProgress = 0;
    const interval = setInterval(() => {
      uploadProgress += 10;
      setProgress(uploadProgress);

      if (uploadProgress >= 100) {
        clearInterval(interval);
      }
    }, 500);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      setFileData(prevState => {
        return { 
          ... prevState,
          filename: shortenFileName(file.name),
          size: file.size
        }
      })

      setUploadStatus('uploading');
      simulateFileUpload();

      setTimeout(() => {
        setUploadStatus('success');

        let _fileItem = { ...fileItem };
        _fileItem['file'] = file;
        onUpdateFileItem(_fileItem);
      }, 2000);
    }
  };

  const onChangeUploadNewFileOrUseInputFile = () => {
    if (uploadNewFile) {
      setUploadNewFile(false);
      setUploadStatus("");
      setProgress(0);

      let _fileItem = { ...fileItem };
      _fileItem['file'] = null;
      onUpdateFileItem(_fileItem);
    } else {
      setUploadNewFile(true);
      setUploadStatus("");
      setProgress(0);

      fileItem.reference_to = null;
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    
    const file = event.dataTransfer.files[0];
    
    if (file) {
      setUploadStatus("uploading");

      setFileData(prevState => {
        return {
          ...prevState,
          filename: shortenFileName(file.name),
          size: file.size
        }
      })

      if (fileInputRef.current && fileInputRef.current.files) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInputRef.current.files = dataTransfer.files;
      }

      simulateFileUpload();

      setTimeout(() => {
        setUploadStatus("success");

        let _fileItem = fileItem ? { ...fileItem } : {};
        _fileItem["file"] = file;

        if (onUpdateFileItem && typeof onUpdateFileItem === "function") {
          onUpdateFileItem(_fileItem);
        }
      }, 2000);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    setProgress(0);
  };
  // const handleClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  const onChangeFileReferenceTo = event => {

    const value = event.value;
    setUploadStatus(value);

    let _fileItem = { ... fileItem };
    _fileItem['reference_to'] = value;

    onUpdateFileItem(_fileItem);
  }

  const inputFileSelectedOption = useMemo(() => {

    if(requirementStage){
      const selectedOption = _.find(requirementStage.input_form.file_items, file => {
        return String(file.id) == String(fileItem.reference_to)
      })

      if(selectedOption){
        setUploadStatus('success');
        return { value: selectedOption.id, label: selectedOption.name }
      } else {
        setUploadStatus('');
        return null
      }
    }
  }, [fileItem.reference_to])

  const inputFileSelectOptions = useMemo(() => {
    if(requirementStage){
      return _.map(requirementStage.input_form.file_items, file => {
        return {
          value: file.id,
          label: file.name
        }
      })
    }
  }, [])

  const useInputFileToOutputFileButton = () => {
    if (requirementStage?.prev_requirement_stage_id) {
      const buttonText = uploadNewFile ? 'Seleccionar archivo' : 'Subir archivo';
      const buttonIcon = uploadNewFile ? 'fas fa-file-alt' : 'fas fa-upload';
      
      return (
        <div className="text-center mx-auto mt-2">
          <button onClick={onChangeUploadNewFileOrUseInputFile} type="button" className="btn btn-link text-muted btn-sm">
            <i className={`${buttonIcon} mr-2`}></i>
            {buttonText}
          </button>
        </div>
      );
    }
  };

  const getStatusBadge = () => {
    switch (uploadStatus) {
      case 'uploading':
        return <div className="badge position-absolute badge-info"><i className="fas fa-spinner fa-spin"></i></div>;
      case 'success':
        return <div className="badge position-absolute badge-info"><i className="fas fa-paperclip"></i></div>;
      case 'error':
        return <div className="badge position-absolute badge-danger"><i className="fas fa-exclamation-triangle"></i></div>;
      default:
        return <div className="badge position-absolute badge-info"><i className="fas fa-upload"></i></div>;
    }
  };

  const shortenFileName = (fileName) => {
    const maxLength = 15;
    const ext = fileName.split('.').pop();
    const baseName = fileName.substring(0, fileName.lastIndexOf('.'));

    if (baseName.length > maxLength) {
      return baseName.substring(0, maxLength) + "..." + ext;
    }
    return fileName;
  };

  const getClassForStatus = (status) => {
    switch (status) {
      case "uploading":
        return "file-uploading";
      case "success":
        return "file-success";
      case "error":
        return "file-error";
      default:
        return "file-waiting";
    }
  };

  const handleRemoveFile = () => {
    setFileData({
      filename: '',
      size: 0
    })
    setUploadStatus("");
    setProgress(0);

    let _fileItem = { ...fileItem };
    _fileItem['file'] = null;
    onUpdateFileItem(_fileItem);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const renderUploadInputStatus = () => {
    switch (uploadStatus) {
      case 'success':
        return (
          <div>
            <Button
              color="danger"
              outline
              className="border-0 btn-sm"
              onClick={handleRemoveFile}
            >
              <i className="fas fa-times"></i>
            </Button>
            <p className="mb-0 lh-1">
              { fileData?.filename }
            </p>
            <ul className="list-unstyled small mb-0 lh-sm">
              <li>
                <span className="text-muted small">
                  { bytesToSize(fileData?.size || 0 )}
                </span>
              </li>
            </ul>
          </div>
        );
      case 'uploading':
        return (
          <div>
            <p className="mb-0 lh-1">
              { fileData?.filename }
            </p>
            <div className="progress mt-2">
              <div
                className="progress-bar bg-info"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        );
      default:
        return (
          <label 
            htmlFor={inputId(formName, 'value')} 
            className={`text-center w-100 text-muted`} 
            style={{ cursor: "pointer" }} 
            onDrop={handleDrop} 
            onDragOver={handleDragOver}
          >
            <i className="fas fa-upload fa-lg mb-2"></i>
            <p className="mb-0 lh-1 small fw-bold">
              Arrastra un archivo aquí o haz clic para subir
            </p>
          </label>
        );
    }
  };

  const inputFile = () => {
    if(!!previewPdfUrlShortlist){
      return (
        <iframe
          width="100%"
          height="500"
          src={ previewPdfUrlShortlist || '' }
          name={ fileItem.name }
          title={ fileItem.name }
          type="application/pdf"
        >
        </iframe>
      )
    } else {
      return(
        <FormGroup>
          <FieldError errors={ _.get(fileItem, 'errors.file') || [] } >
            <div className="card bg-real-light card-material hover-card">
              <div className="card-body">
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className={`fw-bold fs-6 ${isRequired ? 'required' : ''}`}>{fileItem.name}</h3>
                </div>
                <div className={`card doc-upload-card mb-1 ${getClassForStatus(uploadStatus)}`}>
                  <div className={`card-body d-flex align-items-center ${uploadNewFile ? 'drop-zone' : ''}`}>
                    {uploadStatus &&
                      <div className="doc-extension-indicator position-relative mr-2">
                        {getStatusBadge()}
                        <div className="doc-item"></div>
                      </div>
                    }
                    <div className="flex-fill">
                      { fileItem && uploadNewFile ? renderUploadInputStatus() : '' }
                      { uploadNewFileOrUseEntryFileInput() }
                    </div>
                  </div>
                </div>
                {uploadNewFile && 
                  <>
                    <div className="d-flex justify-content-between">
                      {helpingText()}
                    </div>
                    {templateAttachment}
                  </>
                }
                {useInputFileToOutputFileButton()}
              </div>
            </div>
          </FieldError>
        </FormGroup>
      )
    }
  }

  const templateAttachment = useMemo(() => {
    if(fileItem.blob_url){
      return(
        <div className="lh-1">
          <span className='text-muted small'>
            Plantilla de documento:&nbsp;
            <a
              href={ fileItem.blob_url }
              download={ true }
              className="fw-bold"
              target="_blank"
            >
              <i className="fa fa-download mr-2"></i>
              { I18n.t('actions.download') }
            </a>
          </span>
        </div>
      )
    }
  }, [fileItem.attachment])

  return(
    <FormGroup className="col-lg-6 col-md-12">
      { inputFile() }
    </FormGroup>
  )
}

export default React.memo(WorkflowRequirementStageFormItemFileAttributes);
