import React, { useEffect, useState, useCallback } from "react";
import { validateEmail, validateName } from '../../../../helper/form';
import {
  alertConfirmDelete,
  getDataFilter
} from './config';
import {
  validateIdentifierRepeat,
  validateEmailRepeat
} from './validator';
import { options } from '../../../../helper/pagination';
import { defaultTableHeaderStyle } from "../../../../helper/bootstrap-table/helper";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import {
  Input,
  Table,
  Button,
  FormFeedback,
  UncontrolledTooltip
} from 'reactstrap';
import I18n from 'i18n-js';
import swal from "sweetalert2";

import { fetchCustomFieldValue, isAuthorizedCustomFieldToUser } from "../../../../helper/custom_field/helper";

const EmployeeBulkLoadTableErrors = ({data, handleRetryEmployee, isValid, isUpdate, customFieldsHeaders, currentUser}) => {
  const formName = 'laboral_employee_bulk_load_error'
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    setFilter(getDataFilter(data, "error"))
  }, [data, isValid, isUpdate])

  const onChangeCellValues = (e,index, key) =>{
    let value = e?.target?.value
    data[index][key] = value
  }

  const customFieldSelectOptions = useCallback((listOptions) => {
    return listOptions.map((option) => {
      return (
        <option
          key={ _.uniqueId("_custom_fields_error") }
          value={ option }
        >
          { option }
        </option>
      );
    });
  },[])

  const customFieldSelectInput = (value, index, custom) => {
    if(custom.data_type === "list"){
      return (
        <Input
          type="select"
          key={ _.uniqueId("_custom_fields_error") }
          defaultValue={ value }
          onChange={ e => onChangeCellValues(e, index, custom.code) }
          disabled= { !isAuthorizedCustomFieldToUser(custom, currentUser) }
        >
          <option key={ _.uniqueId("_custom_fields_error") } value=""></option>
          { customFieldSelectOptions(custom.list_options) }
        </Input>
      );
    }else{
      return (
        <Input
          type="text"
          key={ _.uniqueId("_custom_fields_error") }
          defaultValue={ value }
          disabled= { !isAuthorizedCustomFieldToUser(custom, currentUser) }
        />
      );
    }
  };

  const getCustomFields = (_custom_fields) => {
    let items = []
    let actions_column = getColumnsActions();
    _.each(_custom_fields, function(custom){
      let item = {
        headerStyle: defaultTableHeaderStyle,
        dataField: custom?.code,
        text: custom?.code,
        filterDisabled: !isAuthorizedCustomFieldToUser(custom, currentUser),
        formatter : function(value,row,index) {
            return customFieldSelectInput(value, index, custom)
        }
      }
      items.push(item)
    })

    return _.concat(items, actions_column);
  }

  const getColumnsActions = () => {
    return [{
      dataField: "actions",
      text: I18n.t('actions.actions'),
      editable: false,
      headerStyle: {...defaultTableHeaderStyle, width: "100px"},
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color="success"
              outline
              className="border-0"
              onClick={ (e) => handleRetry(row) }
            >
              <i className="fa fa-redo-alt"/>
            </Button>
            <Button
              color="danger"
              outline
              className="border-0"
              onClick={ (e) => handleDestroyEmployee(row) }
            >
              <i className="fas fa-trash-alt" />
            </Button>
            { drawSpanEmployeeNew(row) }
          </div>
        );
      }
    }]
  }

  const firstPartColumns = [
    {
      dataField: 'controldoc_id',
      text: 'ControldocID',
      headerStyle: { display : 'none' },
      style: { display : 'none' },
      formatter : function(value,row,index) {
        return (<Input type="hidden" defaultValue={ value } readOnly/>);
      }
    },
    {
      dataField: 'identifier',
      text: I18n.t('activerecord.attributes.laboral/employee.identifier'),
      headerStyle: defaultTableHeaderStyle,
      formatter : function(value,row,index) {
        let valRepeatId = validateIdentifierRepeat(value, data);

        return (
          <>
            <Input type="text" defaultValue={ value } onBlur={e => onChangeCellValues(e, index, "identifier")} />;
            <FormFeedback>{
              valRepeatId ? I18n.t('activerecord.errors.models.laboral/employee.attributes.identifier.taken') : ''
            }</FormFeedback>
          </>
          );
      }
    },
    {
      dataField: 'value',
      text: I18n.t('activerecord.attributes.laboral/employee.email'),
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        let valEmail = !validateEmail(value);
        let valRepeatEmail = validateEmailRepeat(value, data);

        return (
          <>
            <Input type="email" defaultValue={ value } onBlur={e => onChangeCellValues(e, index, "value")} required/>
            <FormFeedback>{
              valEmail ?
                I18n.t('activerecord.errors.models.laboral/employee_config.attributes.email.invalid_format') :
                  valRepeatEmail ? I18n.t('activerecord.errors.models.laboral/employee_config.attributes.email.taken') : ''
            }</FormFeedback>
          </>
        );
      },
    },
    {
      dataField: 'name',
      text: I18n.t('activerecord.attributes.laboral/employee.name'),
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return (
          <>
            <Input type="text" defaultValue={ value } onBlur={e => onChangeCellValues(e, index, "name")} required/>
            <FormFeedback>{ I18n.t('activerecord.errors.models.laboral/employee_config.attributes.name.blank') }</FormFeedback>
          </>
        );
      },
    }
  ];

  const columns = _.concat(firstPartColumns, getCustomFields(customFieldsHeaders))

  const drawSpanEmployeeNew = (row) => {
    if(!validateName(row.controldoc_id)) {
      return(
        <div className="d-inline-block m-2">
          <span id="tooltipTonNewEmployee">
            <i className="fas fa-user-plus text-info"/>
          </span>
          <UncontrolledTooltip key={ _.uniqueId(row.row) } placement="bottom" target="tooltipTonNewEmployee">
            { I18n.t('actions.create') } { I18n.t('activerecord.models.laboral/employee.one') }
          </UncontrolledTooltip>
        </div>
      )
    }
  }

  const validateEmptyIdentifierRepeat = (row, data) => {
    if(row && row.controldoc_id !== ""){
      return validateIdentifierRepeat(row.identifier, data);
    }else{
      return false;
    }
  }

  const validateFields = (item, row) => {
    return (!validateEmail(item.value) || !validateName(item.name) ||
      validateEmptyIdentifierRepeat(row, data) || validateEmailRepeat(row.value, data))
  }

  const handleRetry = (row) => {
    let listWithoutError = [];
    _.each(_.filter(data, item => item.row === row.row), item => {
      if(validateFields(item, row)){
        item.state = "error";
      }else if(!validateName(item.controldoc_id)){
        listWithoutError.push(item);
        item.state = "created";
      }else{
        listWithoutError.push(item);
        item.state = "created";
      }
    })

    if(listWithoutError && listWithoutError.length > 0){
      handleRetryEmployee(data);
    }
  }

  const handleDestroyEmployee = (row) => {
    swal.fire(alertConfirmDelete()).then((result) => {
      if(result.isConfirmed){
        let filteredArray = _.filter(data, item => (item.row === row.row) ? item.is_deleted = true : item );
        handleRetryEmployee(filteredArray);
      }
    })
  };

  const drawErrors = () => {
    if(filter && filter?.length > 0) {
      return(
        <div className="row">
          <h2 className="card-title text-danger mb-2">
              { I18n.t('laboral.bulk.load.employees.form.table_error.title') }  ({ `${ filter?.length }`}), { I18n.t('laboral.bulk.load.employees.form.table_error.title2') }:
          </h2>
          <BootstrapTable
            keyField='row'
            sort={{ dataField: 'name', order: 'asc' }}
            data={ filter }
            columns={ columns }
            wrapperClasses="table-responsive height-600"
            pagination={ paginationFactory(options) }
            hover
            noDataIndication={ I18n.t('laboral.bulk.load.employees.form.without_records') }
          />
        </div>
      )
    }
  }

  return(
    <>
      { drawErrors() }
    </>
  )
}

export default EmployeeBulkLoadTableErrors;
