import React, {
  useRef,
  useMemo
} from 'react';

import I18n from "i18n-js";

import { textFilter } from "react-bootstrap-table2-filter";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";

import BulkRequestDocumentForm from '../../../../bulk/request/document/form';

import DocumentFormContext from '../../../../document/context/document_form_context';

import { fetchCustomFieldValue } from "../../../../helper/custom_field/helper";

import { defaultTableHeaderStyleWhite } from "../../../../helper/bootstrap-table/helper";

import { generateCustomFieldColumnFilter } from '../../../../helper/bootstrap-table/filter/helper';

import {
  index as indexEntities
} from "../../../entity/axios";

import {
  create as createBulkRequest
} from './axios';

const AbstractBulkRequestDocumentForm = props => {
  const bootstrapTableRef = useRef(null);

  const {
    bulkRequest,
    currentEntityType,
    currentUser,
    currentCustomer,
    data: {
      customFields
    }
  } = props;

  const actionsProps = {
    indexEntities: indexEntities,
    createBulkRequest: createBulkRequest,
    showBulkRequestPath: Routes.abstract_bulk_request_document_path,
    indexBulkRequestPath: Routes.abstract_bulk_request_documents_path
  }

  const defaultRequestParams = { current_entity_type_id: currentEntityType.hashid };
  const context = "abstract_bulk";

  const entityConfiguration = {
    key: 'entities',
    form: {
      entityItemsAttributes: {
        key: 'entity_items_attributes',
        entityId: 'entity_id'
      },
    },
    label: {
      singular: I18n.t("abstract.bulk.request.documents.form.tabs.entities", { entity: currentEntityType.plural_name }),
      plural: I18n.t("abstract.bulk.request.documents.form.tabs.entities", { entity: currentEntityType.plural_name })
    },
    navButtonTitle: I18n.t("abstract.bulk.request.documents.form.tabs.entities_title",
      { entity: currentEntityType.plural_name }
    )
  }

  const customFieldEmailNotifications = useMemo(() => {
    const entityTypes = [
      ... currentEntityType.related_entity_types,
      currentEntityType
    ]

    let emailCustomFields = {}

    _.each(entityTypes, entityType => {
      const customFieldEmailNotifications = _.filter(
        entityType.custom_fields, { is_notification_email: true }
      )

      if(customFieldEmailNotifications.length > 0){
        emailCustomFields[entityType.plural_name] =  customFieldEmailNotifications
      }
    })

    return emailCustomFields;
  }, [])

  const documentFormContextValue = {
    currentUser: currentUser || {},
    currentCustomer: currentCustomer || {},
    currentEntityType: currentEntityType || {},
    isPersisted: false,
    context: "abstract_bulk",
    data: {...props?.data, customFieldEmailNotifications: customFieldEmailNotifications},
    entities: [],
  }

  const entityHeaderConfigTab = useMemo(() => {
    return _.flattenDeep([
      {
        label: I18n.t('activerecord.attributes.abstract/entity.identifier'),
        key: 'identifier'
      }, _.map(customFields, customField => {
        return {
          label: customField.label,
          key: `custom_fields.${ customField.code }`
        }
      })
    ])
  }, [])

  // ---------------- START TABLE ----------------
  const tableColumnsDefault = [
    {
      dataField: "identifier",
      text: I18n.t("activerecord.attributes.abstract/entity.identifier"),
      filterByFilterrific: "search_query",
      headerStyle: defaultTableHeaderStyleWhite,
      filter: textFilter({
        placeholder: `Ingrese ${ I18n.t("activerecord.attributes.abstract/entity.identifier") }`
      }),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef } }
        />
      ),
    }
  ]

  const tableColumnsRelatedEntities = () => {
    return _.map(currentEntityType.related_entity_types, function(relatedEntityType, index){
      return {
        dataField: `related_entities[${ index }].identifier`,
        text: `${ I18n.t("activerecord.attributes.abstract/entity.identifier") } ${ relatedEntityType.singular_name }`,
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => value || I18n.t("no_entry"),
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }  }
          />
        ),
      }
    })
  }

  const tableColumnsCustomFields = () => {
    return _.map(customFields, function(customField){
      return {
        dataField: `custom_fields.${ customField.code }`,
        text: _.capitalize(customField.label),
        filterByFilterrific: `custom_fields.${ customField.code }`,
        headerStyle: defaultTableHeaderStyleWhite,
        filter: generateCustomFieldColumnFilter(customField),
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef } }
          />
        ),
        formatter:(value, row, index) => { return fetchCustomFieldValue(customField, value, currentUser) },
      }
    })
  }

  const tableColumns = _.concat(
    tableColumnsDefault,
    tableColumnsRelatedEntities(),
    tableColumnsCustomFields()
  )
  // ---------------- END TABLE ----------------

  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <div className="row">
        <div className="col-12">
          <BulkRequestDocumentForm
            bulkRequest={ bulkRequest }
            bootstrapTableConfiguration={{
              ref: bootstrapTableRef,
              tableColumns: tableColumns
            }}
            configuration={{
              defaultRequestParams: defaultRequestParams,
              entity: entityConfiguration,
              entityHeaderConfigTab: entityHeaderConfigTab,
              context: context,
              formName: 'abstract_bulk_request_document'
            }}
            actions={
              actionsProps
            }
            data={{
              ... props?.data,
              ...{ customFieldEmailNotifications: customFieldEmailNotifications },
              currentUser: currentUser
            }}
          />
        </div>
      </div>
    </DocumentFormContext.Provider>
  )
};

export default AbstractBulkRequestDocumentForm;
