document.addEventListener("DOMContentLoaded", () => {
  const currencyFields = document.querySelectorAll(".currency-field");
  if(currencyFields){
    currencyFields.forEach((field) => {
      field.addEventListener('input', (e) => {
        let value = e.target.value.replace(/\D/g, '');

        e.target.value = new Intl.NumberFormat('es-CL').format(value);
      });

      field.addEventListener('keydown', (e) => {
        if (![8, 46].includes(e.keyCode) && (e.key < '0' || e.key > '9')) {
          e.preventDefault();
        }
      });
    });
  }
});
