import React from 'react';

import { limitCharacters } from "../form";

import I18n from "i18n-js";

export const fetchCustomFieldValue = (customField, value, currentUser, _blank = false) => {
  if(_blank && _.isEmpty(value)){
    return "";
  }

  if(isAuthorizedCustomFieldToUser(customField, currentUser)){
    return limitCharacters(value) || I18n.t("no_entry");
  }

  return maskedCustomFieldValue();
}

export const isAuthorizedCustomFieldToUser = (customField, currentUser) => {
  if(currentUser?.membership?.is_admin || !customField.is_sensitive_data){
    return true;
  }

  return customField.is_sensitive_data && _.includes(currentUser.authorized_custom_field_ids, customField.id)
}

export const maskedCustomFieldValue = () => {
  return '*****'
}

