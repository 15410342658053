import React, {
  useState,
  useMemo
} from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Files from "react-butterfiles";

import I18n from 'i18n-js';

const AbstractBulkLoadDocumentFileInput = props => {
  const maxSize = 10;
  const maxFiles = 100;

  const {
    entities,
    documents,
    setDocuments,
    allowedFileFormats
  } = props;

  const helperTypeErrorFile = type => {
    switch (type){
      case 'unsupportedFileType':
        return 'Formato del archivo no soportado'
      case 'maxSizeExceeded':
        return `El archivo excede el tamaño máximo de ${ maxSize }mb`
      case 'Without':
        return 'No hemos encontado contratación para el archivo'
      case 'multipleMaxCountExceeded':
      return `No puedes cargar más de ${ maxFiles } archivos`
      default:
      return 'Error en el archivo'
    }
  }

  const fileErrors = useMemo(() => {
    if((documents.errors || []).length > 0){
      return(
        <ul className='text-danger mt-3'>
          { _.map(documents.errors, function(error){
            return(
              <li
                key={ _.uniqueId('file-error-') }
              >
                { `${ error?.file?.name || 'Error' }: ${ helperTypeErrorFile(error.type) }.` }
              </li>
            )
          })}
        </ul>
      )
    }
  }, [documents.errors])

  const filename = (file) =>  {
    // cuando el identificador de la contratación es un RUT con puntos, el nombre del archivo viene en el siguiente formato
    // 11.111.111-1.pdf
    // Por lo que tenemos que obtener solo el valor 11.111.111-1
    let nameWithoutExtension = _.replace(file.name, /\.[^\/.]+$/, '')
    let nameOnlyIdentifier = _.split(nameWithoutExtension, '_')[0]
    return nameOnlyIdentifier
  }

  const entityByIdentifier = (identifier) => {
    return _.find(entities, { identifier: identifier })
  }

  const onUploadFiles = uploadFiles => {
    let files = [];
    let errors = []

    _.each(uploadFiles, function(_file){
      const _filename = filename(_file);
      const entity = entityByIdentifier(_filename);

      if(entity){
        files = [ ... files, { entity: entity, file: _file }]
      } else {
        errors = [ ... errors, { file: _file, type: 'Without'}]
      }
    })

    files = _.sortBy(files, file => file.entity.identifier)

    setDocuments({
      files: files,
      errors: errors
    })
  }


  const onDeleteFile = (e, index) => {
    e.preventDefault();

    setDocuments( prevState => {
      let _files = _.remove(prevState.files, (f, i) => i == index)

      return { ... prevState }
    })
  }

  const onDeleteAllDocuments = () => {
    swalWithBootstrap.fire({
      title: 'Eliminar documentos',
      html: '¿Estás seguro de eliminar todos los documentos?',
    }).then( result => {
      if(result.isConfirmed){
        setDocuments({ files: [], errors: [] })
      }
    })
  }

  const fileEntities = () => {
    if(documents.files.length > 0){
      return(
        <div className="row mt-3">
            { _.map(documents.files, function(_document, index){
              return(
                <div className="col-12" key={ _.uniqueId('file-list-') }>
                  <div className="card mb-3 clip">
                    <div className="card-body">
                      <div className="row d-flex align-items-center">
                        <div className="row">
                          <div className="col-md-8">
                            <h2 className="h6 text-dark font-weight-bold d-block text-capitalize mb-2">
                              { _document.entity.identifier }
                            </h2>

                            <ul className="list-unstyled list-inline text-muted mb-2 mb-sm-0 small">
                              <li className="list-inline-item mr-3">
                                { I18n.t('activerecord.attributes.abstract/entity.identifier') }
                                { `: ${ _document.entity.identifier }`}
                              </li>

                              <li className="list-inline-item mr-3">
                                { 'Documento' }
                                { `: ${ _document.file.name || 'Actualidad' }`}
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-4 text-end">
                            <a
                              href='#'
                              className='text-danger'
                              onClick={ e => onDeleteFile(e, index) }
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <Files
          multiple={ true }
          maxSize={ `${ maxSize }mb`}
          multipleMaxSize={ `${ maxSize * maxFiles }mb`}
          multipleMaxCount={ maxFiles }
          accept={ _.map(props?.allowedFileFormats, 'content_type') }
          onError={ errors => {
            setDocuments(prevState => {
              return { ... prevState, errors: [...errors]}
            })
          } }
          onSuccess={ response => {
            onUploadFiles(response)
          } }
        >
          { ( { browseFiles, getDropZoneProps }) => {
            return(
              <div className="row">
                <div className="col-12">
                  <span className="font-weight-bold text-muted">
                    Formatos permitidos: { _.join(_.map(props?.allowedFormatFiles, 'extension'), ' ') }
                  </span>
                  <div className='text-center pt-5 text-muted mb-3'
                    {...getDropZoneProps({
                      style: {
                        minHeight: 150,
                        border: "2px lightgray dashed",
                      },
                    })}
                  >
                    <i className="fas fa-file-upload fa-4x"></i>
                    <p className='mt-3'>
                      Arrastra y suelta tus archivos aquí
                    </p>

                    <p>{ documents.length } archivos cargados</p>
                  </div>

                  { fileErrors }

                  <button
                    onClick={ browseFiles }
                    className='btn btn-success btn-sm'
                  >
                    Subir archivos
                  </button>
                  <button
                    className='btn btn-danger btn-sm ml-1'
                    onClick={ e => onDeleteAllDocuments(e) }
                  >
                    Eliminar archivos
                  </button>
                </div>
              </div>
            )
          }}
        </Files>

        { fileEntities() }
      </div>
    </div>
  )
}

export default AbstractBulkLoadDocumentFileInput;
