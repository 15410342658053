import React from 'react';
import I18n from 'i18n-js';

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";

export const handleStateFilters = (statesFilters, column, filterElement) => {
  let _statesFilters = [...statesFilters];

  let _field = _.find(_statesFilters, { field: column.dataField });

  if(_field == undefined){
    _statesFilters = _.concat(_statesFilters, { field: column.dataField, value: true })
  } else {
    _field.value = !_field.value;
  }


  if(_field && !_field.value){
    filterElement.props.filterState.comparator = ''
    filterElement.props.filterState.filterVal = ''
  }

  return _statesFilters
}


export const drawInputSearch = (statesFilters, column, filterElement) => {

  let _field = _.find(statesFilters, { field: column.dataField });
  if(_field?.value){
    return(
      <div className="">
        { filterElement }
      </div>
    )
  }
}

export const searchByText = (key, filterValue, data) => {
  if(filterValue){
    return _.filter(data, function(item){
      // Obtenemos el valor en caso de que key sea un un cadena de keys, poir ejenmplo: 'entity.client.corporate_config.name'
      let _value = _.get(item, key)
      return _.includes(
        _.toUpper(_.deburr(_value)),
          _.toUpper(_.deburr(filterValue))
      )
    })
  }
  return data;
}

export const sortCaret = (order, column) => {
  if(!order){
    return(
      <button className="btn btn-link m-0 py-0 px-2" type='button'>
        <i className="fas fa-sort"></i>
      </button>
    )
  }else if( order === 'asc'){
    return(
      <button className="btn btn-link m-0 py-0 px-2" type='button'>
        <i className="fas fa-sort-up"></i>
      </button>
    )
  }else if( order == 'desc'){
    return(
      <button className="btn btn-link m-0 py-0 px-2" type='button'>
        <i className="fas fa-sort-down"></i>
      </button>
    )
  }
}

export const generateCustomFieldColumnFilter = (customField) => {
  let _filter = textFilter({
    placeholder: `Ingrese ${ _.lowerCase(customField.label) }`
  })

  if(customField.data_type == 'list'){
    _filter = selectFilter({
      placeholder: "Seleccione una de las opciones",
      options: _.map(customField.list_options, option => ({
        value: option,
        label: _.capitalize(option),
      }))
    })
  }

  return _filter;
}