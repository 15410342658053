import React, { useMemo } from 'react';

import {
  Row,
  Col,

  Button,

  Card,
  CardHeader,
  CardBody,

  FormGroup,
  Form,
  Label,
  Input,

  Collapse
} from 'reactstrap';

import I18n from 'i18n-js';

import {
  inputId,
  inputName,
} from '../../../helper/form';

import FieldError from '../../../helper/FieldError';

import OtpInputForm from '../../../common/otp_input_form';

import pinDynamicImage from 'images/icons/pin-email.png';
import gmailLogo from 'images/logos/gmail_logo';

import {
  sendOtpCodeThroughEmail as sendOtpCodeThroughEmailService,
  enableDynamicPin as enableDynamicPinService,
  disableDynamicPin as disableDynamicPinService
} from '../axios'

const UserProfileSignatureEmailDynamicPinAttributes = props => {
  const {
    user,
    configuration: {
      formName = 'profile',
      context = 'enable_two_factor'
    } = {},
    callbacks: {
      setUser: onSetUser,
      redirectAfterProfileCompleted: redirectAfterProfileCompleted
    }
  } = props;

  const isEmailDynamicPinConfigurated = useMemo(()=>{
    return _.eq(user.signature_method, 'email_dynamic_pin');
  }, [])

  const badgeConfigurated = useMemo(() => {
    return (
      <small>
        <span className="badge badge-primary text-shadow">Configuración actual</span>
      </small>
    )
  }, [])

  const onChangeUser = (event, key)=> {
    let value = event.target.value;
    const type = event.target.type;
    if(type == 'checkbox'){
      value = event.target.checked ? value : null
    }

    onSetUser(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeOtpToken = (value, attributeName) => {
    onSetUser(prevState => {
      return { ... prevState, [attributeName]: value }
    })
  }

  const emailContext = useMemo(() => {
    // enable_two_factor or disable_two_factor
    return context
  }, [context])

  const onSendOtpCodeEmailService = (event) => {
    event.preventDefault();
    // Send otp code by email
    sendOtpCodeThroughEmailService(emailContext, response => {
      if(response.status == 200){
        swalToastBootstrap.fire({
          icon: 'success',
          title: 'Hemos enviado el PIN de verificación al correo electrónico'
        });
      } else {
        swalToastBootstrap.fire({
          icon: 'error',
          title: 'Tenemos problemas al generar el PIN'
        });
      }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[otp_code_token]`, user.otp_code_token || '');
    formData.append(`${ formName }[signature_method]`, 'email_dynamic_pin');

    return formData;
  }

  const dynamicPinService = useMemo(() => {
    if(context == 'enable_two_factor'){
      return enableDynamicPinService
    } else if(context == 'disable_two_factor'){
      return disableDynamicPinService
    }
  }, [])

  const onSubmit = event => {
    event.preventDefault();

    const formData = getFormData();

    dynamicPinService(formData, response => {
      if(response.status == 200){
        if(_.isFunction(redirectAfterProfileCompleted) && response.data.completed_profile){
          redirectAfterProfileCompleted()
        } else {
          location.reload();
        }
      } else {
        onSetUser(response.data)
      }
    })
  }

  const titleHeader = useMemo(() => {
    if(context == 'enable_two_factor'){
      return 'Enviar PIN por Correo electrónico'
    } else if(context == 'disable_two_factor'){
      return 'Desactivar Envio de PIN por Correo electrónico'
    }
  }, [])

  const submitButton = useMemo(() => {
    if(context == 'enable_two_factor'){
      return (
        <Button
          color='success'
          type='submit'
        >
          Habilitar método de firma
        </Button>
      )
    } else if(context == 'disable_two_factor'){
      return (
        <Button
          color='danger'
          type='submit'
        >
          Desactivar método de firma
        </Button>
      )
    }
  }, [])

  return(
    <Row>
      <Col>
        <Card className={`border-0 mb-3 hover-shadow-card options-card ${ isEmailDynamicPinConfigurated ? "disabled" : ""}`}>
          <CardHeader
            className='border-bottom-0 option-btn p-0 border-0 bg-real-light'
            type="button"
            htmlFor={ inputId(formName, 'signature_method_email_dynamic_pin') }
          >
            <FormGroup className='pl-0' check>
              <Label className='mb-0 py-3 pl-5 pr-3 d-block text-left w-100'>
                {
                  !isEmailDynamicPinConfigurated && (
                    <Input
                      type='checkbox'
                      id={ inputId(formName, 'signature_method_email_dynamic_pin') }
                      name={ inputName(formName, 'signature_method_email_dynamic_pin') }
                      checked={ user.signature_method == 'email_dynamic_pin' }
                      value={ 'email_dynamic_pin' }
                      onChange={ event => onChangeUser(event, 'signature_method') }
                    />
                  )
                }
                <div className="d-flex align-items-start justify-content-start pl-2">
                  <img src={ pinDynamicImage } alt="Pin Personalizado" style={{width: 40}} className='mr-1'/>
                  <div className='pl-1'>
                    <div className='d-flex'>
                      <h3 className="fs-5 font-weight-bold mb-0 mr-2">
                        { titleHeader }
                      </h3>
                      { isEmailDynamicPinConfigurated && badgeConfigurated }
                    </div>
                    { context == 'enable_two_factor' &&
                      <p className="mb-0 small lh-sm">
                        Puedes solicitar que enviemos un
                        <strong>&nbsp;PIN dinámico&nbsp;</strong>
                        a tu correo electrónico.
                      </p>
                    }
                  </div>
                </div>
              </Label>
            </FormGroup>
          </CardHeader>

          <Collapse
            // Solo muestra el formulario si el usuario selecciona email_dynamic_pin
            // y no lo tenía configurado previamente
            isOpen={ !isEmailDynamicPinConfigurated && user.signature_method == 'email_dynamic_pin' }
          >
            <Form onSubmit={ onSubmit }>
              <CardBody>
                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-1 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 1: </strong>
                      <span>Solicita el envío de PIN de confirmación a tu correo registrado en ControlDoc:</span>
                      <strong>&nbsp;{user.email || ''}&nbsp;</strong>
                    </p>

                    <div className="text-left">
                      <Button
                        color='primary'
                        onClick={ event => onSendOtpCodeEmailService(event) }
                      >
                        Enviar PIN al correo
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 2: </strong>
                      <span>Ingresa el PIN enviado a tu correo</span>
                    </p>

                    <div className="text-center my-3">
                      <FieldError errors={ _.get(user, 'errors.otp_code_token') }>
                        <OtpInputForm
                          configuration={{
                            formName: formName,
                            attributeName: 'otp_code_token'
                          }}
                          callbacks={{
                            onChangeOtpToken: onChangeOtpToken
                          }}
                          pinCode={ user.otp_code_token || '' }
                        />
                      </FieldError>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='text-center'>
                    { submitButton }
                  </Col>
                </Row>
              </CardBody>
            </Form>
          </Collapse>
        </Card>
      </Col>
    </Row>
  )
}

export default UserProfileSignatureEmailDynamicPinAttributes;