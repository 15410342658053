import React, { useState } from 'react';

import I18n from 'i18n-js';
import { Col, Row } from 'reactstrap';

import UserProfileSignatureCustomPinAttributes from './signature_method/signature_custom_pin_attributes';
import UserProfileSignatureEmailDynamicPinAttributes from './signature_method/signature_email_dynamic_pin_attributes';
import UserProfileSignatureAppDynamicPinAttributes from './signature_method/signature_app_dynamic_pin_attributes';

const UserProfileSignatureMethodAttributes = props => {
  const {
    callbacks
  } = props;

  const [user, setUser] = useState(props?.user || {} )

  return(
    <Row>
      <Col md='12'>
        <UserProfileSignatureCustomPinAttributes
          user={ user }
          callbacks={ {...callbacks, setUser: setUser} }
        />
      </Col>

      <Col md='12'>
        <UserProfileSignatureEmailDynamicPinAttributes
          user={ user }
          callbacks={ {...callbacks, setUser: setUser} }
        />
      </Col>

      <Col md='12'>
        <UserProfileSignatureAppDynamicPinAttributes
          user={ user }
          callbacks={ {...callbacks, setUser: setUser} }
        />
      </Col>
    </Row>
  )
}

export default UserProfileSignatureMethodAttributes;