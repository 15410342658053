import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BulkRequestZipDocumentTable from "../../../../../bulk/request/zip/document/table";
import BootstrapTableSortHeader from "../../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../../helper/bootstrap-table/filter/filter_header";

import { index as indexAbstractDocument, getByIds as getAbstractDocumentByIds } from "../../../../document/axios";
import { defaultTableHeaderStyleWhite } from "../../../../../helper/bootstrap-table/helper";
import { create } from "../document/axios";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

import { limitCharacters } from "../../../../../helper/form"
import { generateCustomFieldColumnFilter } from "../../../../../helper/bootstrap-table/filter/helper";
import { fetchCustomFieldValue, isAuthorizedCustomFieldToUser } from "../../../../../helper/custom_field/helper"

const AbstractBulkRequestZipDocumentForm = props => {
  const { documentTypes, aasmStates, securityLayers } = props?.filters;
  const { currentCustomer, currentEntityType, currentUser } = props;
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);

  // Ref
  const bootstrapTable = useRef(null);

  const columns = [
    {
      dataField: "entity.identifier",
      text: I18n.t("activerecord.attributes.abstract/entity.identifier"),
      filterByFilterrific: "search_query",
      sortByFilterrific: "entity_identifier_",
      filter: textFilter({
        placeholder: `Ingrese el identificador del ${I18n.t(
          currentEntityType.singular_name
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.document_type"),
      filterByFilterrific: "by_document_type",
      sortByFilterrific: "document_type_name_",
      filter: selectFilter({
        options: _.map(documentTypes, document_type => ({
          value: document_type.id,
          label: document_type.name,
        })),
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.created_at"),
      filterByFilterrific: 'search_by_created_at',
      filter: textFilter({
        placeholder: `Ingrese la ${I18n.t(
          "activerecord.attributes.laboral/hiring_document.created_at"
        )}`,
      }),
      sortByFilterrific: "created_at_to_s_",
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "aasm_state",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.aasm_state.one"),
      filterByFilterrific: "by_aasm_state",
      sortByFilterrific: "aasm_state_",
      filter: selectFilter({
        placeholder: `Seleccione un estado`,
        options: aasmStates,
      }),
      formatter: (value, row, index) => I18n.t(`activerecord.attributes.laboral/hiring_document.aasm_state.${ value }`),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      )
    }
  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        filterDisabled: !isAuthorizedCustomFieldToUser(custom, currentUser),
        filter: generateCustomFieldColumnFilter(custom),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return fetchCustomFieldValue(custom, value, currentUser) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const columnsDocuments = _.concat(
    columns,
    getCustomFields(props?.customFields)
  );

  const tableConfirmDocument = (documents, drawDeleteSelectedDocument) => {
    return (
      <div className="d-flex">
        <table className="table mt-2 table-responsive-md">
          <thead>
            <tr>
              <th>
                {I18n.t("activerecord.attributes.abstract/entity.identifier")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.abstract/document.document_type")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.abstract/document.created_at")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(documents, function (document, index) {
              return (
                <tr key={`tr-list-documents-${document.id}`}>
                  <td>{document.entity?.name}</td>
                  <td>{document?.document_type?.name}</td>
                  <td>{document.created_at_to_s}</td>
                  <td>{drawDeleteSelectedDocument(document)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <BulkRequestZipDocumentTable
        showBulkMyFilter={ false }
        bulkRequest={bulkRequest}
        columns={columnsDocuments}
        _ref={bootstrapTable}
        tableConfirmDocument={tableConfirmDocument}
        configuration={{
          context: "abstract",
          defaultRequestParams: {
            current_entity_type_id: props.currentEntityType.hashid
          },
        }}
        actions={{
          indexDocument: indexAbstractDocument,
          createService: create,
          getDocumentByIds: getAbstractDocumentByIds
        }}
        buttonActions={{
          show: Routes.abstract_bulk_request_zip_document_path,
          back: Routes.abstract_bulk_request_zip_documents_path({ current_entity_type_id: props?.currentEntityType?.hashid }),
        }}
      />
    </div>
  );
};

export default AbstractBulkRequestZipDocumentForm;
