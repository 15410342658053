import React, { useState } from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import Select from 'react-select';
import { inputId, inputName, drawTooltipHelp, formatToCurrency } from '../../helper/form';
import I18n from 'i18n-js';
import FieldError from '../../helper/FieldError';

import AbstractEntityConfigList from './config_list';

import { create as createAbstractEntity, update as updateAbstractEntity } from './axios';
import { fetchCustomFieldValue, isAuthorizedCustomFieldToUser } from "../../helper/custom_field/helper";

import _ from 'lodash';

const AbstractEntityForm = props => {
  const formName = 'abstract_entity';
  const [entity, setEntity] = useState(props?.entity || {});
  const currentEntityType = props?.currentEntityType;
  const { customer, currentUser, securityLayers, data } = props;
  const groupsCustomFields = _.groupBy(data.customFields, 'group_name');
  const params = props?.data?.params;


  const cloneFolders = _.sortBy(props?.data?.originalEntity?.folder_check_lists || [], folder => folder.name )

  //Start Handle

  const handleEntity = (event, key) => {
    let _entity = { ...entity };
    let value = event?.target?.value;

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }

    _entity[key] = value;
    setEntity(_entity)
  }

  const handleEntityConfigs = _entityConfigs => {
    let _entity = { ...entity };

    _entity.entity_configs = _entityConfigs;

    setEntity(_entity);
  }

  const handleCustomFields = (e, customField) => {
    let _entity = { ...entity };
    let value = e?.target?.value;
    let key = customField.code;

    if(customField.data_type == 'currency'){
      value = formatToCurrency(value)
    }

    _entity.custom_fields[key] = value
    setEntity(_entity)
  }

  const handleConfirm = () => {
    let formData = getFormData();

    if(entity.id){
      updateAbstractEntity({ id: entity.id, current_entity_type_id: currentEntityType.hashid }, formData, response => {
        if(response.status == 200){
          handleSubmitRedirect(response);
        } else {
          setEntity(response.data);
        }
      })
    } else {
      createAbstractEntity(currentEntityType.hashid, formData, response => {
        if(response.status == 201){
          handleSubmitRedirect(response)
        } else {
          setEntity(response.data);
        }
      })
    }
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('abstract.entities.form.confirm.title', { entity: currentEntityType.singular_name }),
      html: I18n.t('abstract.entities.form.confirm.message', { entity: currentEntityType.singular_name }),
    }).then( result => {
      if(result.isConfirmed){
        handleConfirm();
      }
    })
  }

  const handleSubmitRedirect = (response) => {
    if(response?.status == 200 || response?.status == 201){
      window.location = Routes.abstract_entity_path(currentEntityType.hashid, response.data.hashid);
    }
  }

  //End Handle

  //Start Draw

  const customFieldGroupName = (groupName) => {
    if (groupName == 'null' || groupName == '' || groupName == null){
      return 'Sin Grupo'
    } else {
      return groupName
    }
  }


  const drawGroupCustomFields = () => {
    return _.map(groupsCustomFields, function(customFields, groupName){
      let label = customFieldGroupName(groupName)

      return(
        <div className="card card-outline card-primary" key={ `CustomFieldGroup-${ _.camelCase(groupName) }` }>
          <div className="card-header">
            <strong>
              { label }
            </strong>
          </div>
          <div className="card-body">
            { showCustomFields(customFields) }
          </div>
        </div>
      )
    })
  }

  const showCustomFields = (customFields) => {
    return _.map(customFields, function(customField){
      const value = customField.data_type == 'date' ? moment(entity.custom_fields[customField.code], 'YYYY-MM-DD').format('YYYY-MM-DD') : entity.custom_fields[customField.code]

      return customFieldSelectInput(customField, value)
    })
  }

  const customFieldSelectInput = (customField, value) => {
    let inputClass = customField.data_type === 'currency' ? 'currency-field' : '';
    let fetchedValue = fetchCustomFieldValue(customField, value, currentUser, true)

    if(customField.data_type == 'list'){
      return(
        <FormGroup key={ customField.code }>
          <FieldError errors={ entity?.errors[`custom_fields_${ customField.code }`] || [] }>
            <Label for={ inputId(formName, `custom_fields_${ customField.code }`) }>
              { customField.label }
            </Label>
            <Select
              id={ inputId(formName, `custom_fields_${ customField.code }`) }
              name={ inputName(`${ formName }[custom_fields]`, `${ customField.code }`) }
              invalid={ _.has(entity?.errors, `custom_fields_${ customField.code }`) }
              onChange={ e => handleCustomFields({ target: { value: e?.value } }, customField ) }
              value={ { label: fetchedValue, value: fetchedValue } || '' }
              options={ customField.list_options.map(option => ({ label: option, value: option })) }
              type={ customField.data_type }
              disabled= { !isAuthorizedCustomFieldToUser(customField, currentUser) }
              placeholder={ `-- Selecciona una opción --` }
            />
          </FieldError>
        </FormGroup>
      )
    }else{
      return(
        <FormGroup key={ customField.code }>
          <FieldError errors={ entity?.errors[`custom_fields_${ customField.code }`] || [] }>
            <Label for={ inputId(formName, `custom_fields_${ customField.code }`) }>
              { customField.label }
            </Label>
            <Input
              id={ inputId(formName, `custom_fields_${ customField.code }`) }
              name={ inputName(`${ formName }[custom_fields]`, `${ customField.code }`) }
              invalid={ _.has(entity?.errors, `custom_fields_${ customField.code }`) }
              onChange={ (e) => handleCustomFields(e, customField) }
              className={ inputClass }
              value={ fetchedValue }
              type={ customField.data_type }
              disabled= { !isAuthorizedCustomFieldToUser(customField, currentUser) }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const drawAbstractEntityConfigList = () => {
    return(
      <AbstractEntityConfigList
        configuration={{
          formName: formName
        }}
        currentEntity={ entity }
        entityConfigs={ entity.entity_configs }
        currentEntityType={ props?.currentEntityType }
        callbackSetEntityConfigs={ handleEntityConfigs }
      />
    )
  }

  const drawSelectSecurityLayers = () => {
    if(customer?.security_layer){
      return(
        <FormGroup>
          <FieldError errors={ entity?.errors?.security_layer_id || [] }>
            <Label for={ inputId(formName, 'security_layer_id') }>
              { I18n.t('activerecord.attributes.abstract/entity.security_layer_id') }
            </Label>
              { drawTooltipHelp(I18n.t('abstract.entities.security_layer_help', { entity: currentEntityType.singular_name })) }
            <Input
              id={ inputId(formName, 'security_layer_id') }
              type='select'
              name={ inputName(formName, 'security_layer_id') }
              invalid={ _.has(entity?.errors, 'security_layer_id') }
              onChange={ e => handleEntity(e, 'security_layer_id') }
              value={ entity?.security_layer_id || '' }
            >
              <option value="">
                { I18n.t('inputs.select_a_option') }
              </option>
              { drawSecurityLayerOptions() }
            </Input>
          </FieldError>
        </FormGroup>
      )
    }
  }

  const drawSecurityLayerOptions = () => {
    return _.map(securityLayers, security_layer => {
      return(
        <option
          value={ security_layer.id }
          key={ _.uniqueId('security_layer-option-') }
        >
          { security_layer.name }
        </option>
      )
    })
  }

  //End Draw

  //Start Get

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, entity.id || '');
    formData.append(`${ formName }[identifier]`, entity.identifier || '');
    formData.append(`${ formName }[security_layer_id]`, entity.security_layer_id || '');
    formData.append(`${ formName }[clone_folders]`, entity.clone_folders || false);

    if(entity.clone_folders){
      _.each(entity.clone_folder_ids, cloneFolderId => {
        formData.append(`${ formName }[clone_folder_ids][]`, cloneFolderId || '');
      })
    }
    _.each(entity.entity_configs, function(entityConfig, index){
      formData.append(`${ formName }[entity_configs_attributes][${ index }][id]`, entityConfig?.id || '');
      formData.append(`${ formName }[entity_configs_attributes][${ index }][entity_id]`, entityConfig?.entity_id || '');
      formData.append(`${ formName }[entity_configs_attributes][${ index }][abstract_entity_type_id]`, entityConfig?.abstract_entity_type_id || '');
    })

    _.each(data.customFields, function(customField){
      let value = entity.custom_fields[customField.code] || '';
      formData.append(`${ formName }[custom_fields][${ customField.code }]`, value);
    })

    return formData;
  }

  //End Get

  const cloneFolderSwitchInput = () => {
    if(params?.action == 'clone'){
      return(
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'clone_folders') }
                id={ inputId(formName, 'clone_folders') }
                invalid={ _.has(entity?.errors, 'clone_folders') }
                onChange={ e => handleEntity(e, 'clone_folders') }
                checked={ entity?.clone_folders || false }
              />
              <Label
                className='custom-control-label'
                for={ inputId(formName, 'clone_folders') }
              >
                { I18n.t(`activerecord.attributes.abstract/entity.clone_folders`) }
              </Label>
            </FieldError>
          </div>
        </FormGroup>
      )
    }
  }

  const onChangeCloneFolderIds = (event, value) => {
    const checked = event.target.checked;
    let cloneFolderIds = [... entity.clone_folder_ids]

    if(checked){
      cloneFolderIds.push(value)
    } else {
      _.remove(cloneFolderIds, id => id == value)
    }

    setEntity(prevState => {
      return { ... prevState, 'clone_folder_ids': cloneFolderIds }
    })
  }

  const cloneFoldersItems = () => {
    if(params?.action == 'clone' && entity.clone_folders){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card bg-real-light">
              <div className="card-body">
                <div className="row">
                  { _.map(cloneFolders, folder => {
                    return(
                      <div className="col-6" key={ `cloneFoldersItems-${ folder.id }` }>
                        <FormGroup>
                          <div className='custom-switch'>
                            <FieldError errors={ [] }>
                              <Input
                                className='custom-control-input'
                                type='checkbox'
                                value={ folder.id }
                                name={ `${ inputName( formName, 'clone_folder_ids')  }[]` }
                                id={ inputId( formName, `clone_folder_ids_${ folder.id }`) }
                                onChange={ e => onChangeCloneFolderIds(e, folder.id) }
                                checked={ _.includes(entity.clone_folder_ids, folder.id) }

                              />
                              <Label
                                className='custom-control-label'
                                htmlFor={ inputId( formName, `clone_folder_ids_${ folder.id }`) }
                              >
                                { folder.name }
                              </Label>
                            </FieldError>
                          </div>
                        </FormGroup>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const cloneFoldersCard = () => {
    if(params?.action == 'clone' && props?.data?.originalEntity && cloneFolders.length > 0){
      return(
        <div className="card card-material">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                Clonar carpetas
              </div>
            </div>
          </div>

          <div className="card-body">
            { cloneFolderSwitchInput() }
            { cloneFoldersItems() }
          </div>
        </div>
      )
    }
  }

  return(
    <div className='row'>
      <div className='col-12'>
        <div className="card card-outline card-primary">
          <div className="card-body">
            <FormGroup>
              <FieldError errors={ entity?.errors?.identifier || [] }>
                <Label for={ inputId(formName, 'identifier') }>
                  { I18n.t('activerecord.attributes.abstract/entity.identifier') }
                </Label>
                  { drawTooltipHelp(I18n.t('abstract.entities.identifier_help', { entity: currentEntityType.singular_name })) }
                <Input
                  id={ inputId(formName, 'identifier') }
                  name={ inputName(formName, 'identifier') }
                  invalid={ _.has(entity?.errors, 'identifier') }
                  onChange={ e => handleEntity(e, 'identifier') }
                  defaultValue={ entity?.identifier || '' }
                  />
              </FieldError>
            </FormGroup>

            { drawSelectSecurityLayers() }

            { drawAbstractEntityConfigList() }
          </div>
        </div>
      </div>

      <div className='col-12'>
        { drawGroupCustomFields() }

        { cloneFoldersCard() }

        <FormGroup className='text-end'>
          <a
            href={ Routes.abstract_entities_path({ current_entity_type_id: currentEntityType.hashid }) }
            className='btn btn-default'
          >
            { I18n.t('actions.back') }
          </a>

          <Button
            color='success'
            className='ml-2'
            onClick={ e => handleConfirmSubmit() }
          >
            { I18n.t('actions.save') }
          </Button>
        </FormGroup>
      </div>
    </div>
  )
}

export default AbstractEntityForm;
