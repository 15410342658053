$(function(){
  const workflowForm = document.querySelector('#new_workflow_process, #edit_workflow_process');

  if(workflowForm){
    const requireDisclaimerCheckbox = document.getElementById('workflow_process_require_disclaimer')
    const limitAccessCheckbox = document.getElementById('workflow_process_limit_access_to_ebooks')

    const disclaimerContainer = document.getElementById('disclaimer_container')
    const accessToEbooksContainer = document.getElementById('access_to_ebooks_container')

    requireDisclaimerCheckbox.addEventListener('change', event => {
      const checked = event.target.checked;
      toggleDisclaimerContainer(checked);
    })

    limitAccessCheckbox.addEventListener('change', event => {
      const checked = event.target.checked;
      toggleAccessToEbooksContainer(checked);
    })

    const toggleDisclaimerContainer = (requireDisclaimer) => {
      if(requireDisclaimer){
        disclaimerContainer.classList.remove('d-none')
      } else {
        disclaimerContainer.classList.add('d-none')
      }
    }

    const toggleAccessToEbooksContainer = (limitAccess) => {
      if(limitAccess){
        accessToEbooksContainer.classList.remove('d-none')
      } else {
        accessToEbooksContainer.classList.add('d-none')
      }
    }

    toggleDisclaimerContainer(requireDisclaimerCheckbox.checked);
    toggleAccessToEbooksContainer(limitAccessCheckbox.checked);
  }
});
