import React, { useMemo, useState } from 'react';

import {
  Row,
  Col,

  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,

  Collapse,

  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import I18n from 'i18n-js';

import { QRCodeSVG } from 'qrcode.react';

import {
  inputId,
  inputName,
} from '../../../helper/form';

import FieldError from '../../../helper/FieldError';

import OtpInputForm from '../../../common/otp_input_form';

import pinDynamicImage from 'images/icons/pin-dynamic.png';
import googleAuthLogo from 'images/logos/google_auth_logo';
import appStoreBadge from 'images/logos/badge_app_store.png';
import googlePlayBadge from 'images/logos/badge_google_play';

import {
  sendOtpCodeThroughEmail as sendOtpCodeThroughEmailService,
  enableDynamicPin as enableDynamicPinService,
  disableDynamicPin as disableDynamicPinService
} from '../axios'

const appName = 'Google Authenticator'

const UserProfileSignatureAppDynamicPinAttributes = props => {
  const [openModalGAuthenticatorModal, setOpenModalGAuthenticatorModal] = useState(false)

  const {
    user,
    configuration: {
      formName = 'profile',
      context = 'enable_two_factor'
    } = {},
    callbacks: {
      setUser: onSetUser,
      redirectAfterProfileCompleted: redirectAfterProfileCompleted
    }
  } = props;

  const isAppDynamicPinConfigurated = useMemo(()=>{
    return _.eq(user.signature_method, 'app_dynamic_pin');
  }, [])

  const badgeConfigurated = useMemo(() => {
    return (
      <small>
        <span className="badge badge-primary text-shadow">Configuración actual</span>
      </small>
    )
  }, [])

  const onChangeUser = (event, key)=> {
    let value = event.target.value;
    const type = event.target.type;
    if(type == 'checkbox'){
      value = event.target.checked ? value : null
    }

    onSetUser(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeOtpToken = (value, attributeName) => {
    onSetUser(prevState => {
      return { ... prevState, [attributeName]: value }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[otp_code_token]`, user.otp_code_token || '');
    formData.append(`${ formName }[signature_method]`, 'app_dynamic_pin');

    return formData;
  }

  const dynamicPinService = useMemo(() => {
    if(context == 'enable_two_factor'){
      return enableDynamicPinService
    } else if(context == 'disable_two_factor'){
      return disableDynamicPinService
    }
  }, [])

  const onSubmit = event => {
    event.preventDefault();

    const formData = getFormData();

    dynamicPinService(formData, response => {
      if(response.status == 200){
        if(_.isFunction(redirectAfterProfileCompleted) && response.data.completed_profile){
          redirectAfterProfileCompleted()
        } else {
          location.reload();
        }
      } else {
        onSetUser(response.data)
      }
    })
  }

  const titleHeader = useMemo(() => {
    if(context == 'enable_two_factor'){
      return `Generar PIN desde ${ appName }`
    } else if(context == 'disable_two_factor'){
      return `Desactivar PIN desde ${ appName }`
    }
  }, [])

  const submitButton = useMemo(() => {
    if(context == 'enable_two_factor'){
      return (
        <Button
          color='success'
          type='submit'
        >
          Habilitar método de firma
        </Button>
      )
    } else if(context == 'disable_two_factor'){
      return (
        <Button
          color='danger'
          type='submit'
        >
          Desactivar método de firma
        </Button>
      )
    }
  }, [])

  const GAuthenticatorInstructionButton = useMemo(() => {
    if(context == 'enable_two_factor'){
      return(
        <Button
          color='primary'
          onClick={ event => setOpenModalGAuthenticatorModal(true) }
        >
          Obtener PIN
        </Button>
      )
    }
  })

  return(
    <Row>
      <Col>
        <Card className={`border-0 mb-3 hover-shadow-card options-card ${ isAppDynamicPinConfigurated ? "disabled" : ""}`}>
          <CardHeader
            className='border-bottom-0 option-btn p-0 border-0 bg-real-light'
            type="button"
            htmlFor={ inputId(formName, 'signature_method_app_dynamic_pin') }
          >
            <FormGroup className='pl-0' check>
              <Label className='mb-0 py-3 pl-5 pr-3 d-block text-left w-100'>
                {
                  !isAppDynamicPinConfigurated && (
                    <Input
                      type='checkbox'
                      id={ inputId(formName, 'signature_method_app_dynamic_pin') }
                      name={ inputName(formName, 'signature_method_app_dynamic_pin') }
                      checked={ user.signature_method == 'app_dynamic_pin' }
                      value={ 'app_dynamic_pin' }
                      onChange={ event => onChangeUser(event, 'signature_method') }
                    />
                  )
                }
                <div className="d-flex align-items-start justify-content-start pl-2">
                  <img src={ pinDynamicImage } alt="Pin Personalizado" style={{width: 40}} className='mr-1'/>
                  <div className='pl-1'>
                    <div className='d-flex'>
                      <h3 className="fs-5 font-weight-bold mb-0 mr-2">
                        { titleHeader }
                      </h3>
                      { isAppDynamicPinConfigurated && badgeConfigurated }
                    </div>
                    { context == 'enable_two_factor' &&
                      <p className="mb-0 small lh-sm">
                        Puedes utilizar un
                        <strong>&nbsp;PIN dinámico&nbsp;</strong>
                        generado por la
                        <strong>&nbsp;aplicación instalada&nbsp;</strong>
                        en tu teléfono.
                      </p>
                    }
                  </div>
                </div>
              </Label>
            </FormGroup>
          </CardHeader>

          <Collapse
            // Solo muestra el formulario si el usuario selecciona app_dynamic_pin
            // y no lo tenía configurado previamente
            isOpen={ !isAppDynamicPinConfigurated && user.signature_method == 'app_dynamic_pin' }
          >
            <Form onSubmit={ onSubmit }>
              <CardBody>
                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-1 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 1: </strong>
                      <span>Obtén el <strong>PIN dinámico</strong> generado por la Aplicación <strong>Google Authenticator</strong></span>
                    </p>
                    <div className="text-left">
                      { GAuthenticatorInstructionButton }
                    </div>
                    <GoogleAuthenticatorInstructionModal
                      user={ user }
                      openModal={ openModalGAuthenticatorModal }
                      setOpenModal={ setOpenModalGAuthenticatorModal }
                    />
                  </Col>
                </Row>

                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 2: </strong>
                      <span>Ingresa el PIN generado por la App</span>
                    </p>

                    <div className="text-center my-3">
                      <FieldError errors={ _.get(user, 'errors.otp_code_token') }>
                        <OtpInputForm
                          configuration={{
                            formName: formName,
                            attributeName: 'otp_code_token'
                          }}
                          callbacks={{
                            onChangeOtpToken: onChangeOtpToken
                          }}
                          pinCode={ user.otp_code_token || '' }
                        />
                      </FieldError>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='text-center'>
                    { submitButton }
                  </Col>
                </Row>
              </CardBody>
            </Form>
          </Collapse>
        </Card>
      </Col>
    </Row>
  )
}

export default UserProfileSignatureAppDynamicPinAttributes;


const GoogleAuthenticatorInstructionModal = props => {
  const {
    user,
    openModal,
    setOpenModal
  } = props

  return(
    <Modal
      isOpen={ openModal }
      toggle={ event => setOpenModal(false) }
      unmountOnClose={ true }
      className='text-dark'
      size='lg'
    >
      <ModalHeader
        toggle={ event => setOpenModal(false) }
        className="dynamic-pin-info"
      >

        <div className="float-left mr-2">
          <img src={ googleAuthLogo } style={{ width: 45 }} className='mr-1' alt='Google Authenticator'/>
        </div>

        <div className="lh-1">
          <span className="d-block">Configurar método de firma</span>
          <div className="font-weight-bold">Usando Google Authenticator</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="mb-4 sign-steps">
          <div className="step-item mb-2">
            <div className="number-step float-left font-weight-bold h2 text-white">1</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Si no tienes la aplicación puedes descargarla en tu teléfono desde uno de estos links:
              </div>
            </div>
          </div>
          <div className="text-center mb-4">
            <a
              href='https://itunes.apple.com/cl/app/google-authenticator/id388497605'
              target='_blank'
              style={{ height: 55 }}
              className='mt-2 mr-2'
            >
              <img src={ appStoreBadge } alt="App Store" style={{ height: 55 }}/>
            </a>

            <a
              href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
              target='_blank'
              style={{ height: 55 }}
              className='mt-2 mr-2'
            >
              <img src={ googlePlayBadge } alt="Google Play" style={{ height: 55 }}/>
            </a>
          </div>

          <div className="step-item mb-2">
            <div className="number-step float-left font-weight-bold h2 text-white">2</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Abre Google Authenticator y escanea este código con la aplicación:
              </div>
            </div>
          </div>
          <div className="text-center my-3">
            <QRCodeSVG
              value={ `otpauth://totp/Controldoc?secret=${ user.otp_secret_key }` }
            />

          </div>
          <div className="text-center my-3">
            O ingresa manualmente el siguiente código en la aplicación

            <div className="mt-2 text-center">
              <p className="lead font-weight-bold">
                { user.otp_secret_key }
              </p>
            </div>
          </div>

          <div className="step-item mb-2">
            <div className="number-step float-left font-weight-bold h2 text-white">3</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Google Authenticator te mostrará el PIN de confirmación que debes ingresar en ControlDoc
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={event => setOpenModal(false) }>
          { I18n.t('actions.close') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}
