import React, {
  useMemo,
  useContext
} from 'react';

import {
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowDiagramContext from '../diagram_context';

import WorkflowStageFileItemAttributes from './attributes_field'
import { drawTooltipHelp } from "../../helper/form";

const WorkflowStageItemFileList = props => {
  const {
    fileItems,
    currentNode,
    setCurrentNode,
    configuration: {
      stageForm,
      showGroupName
    },
    data: {
      groupNameOptions = []
    }
  } = props;

  const {
    callbacks: {
      isNodeShortlist
    },
  } = useContext(WorkflowDiagramContext);

  const hasFileItemsActive = useMemo(() => {
    return _.some(fileItems, item => !item._destroy)
  }, [fileItems])

  const generateCustomId = useMemo(() => {
    return `${ Math.random().toString(36).slice(2) }-${ moment().toDate().getTime() }-${ fileItems.length }`;
  }, [fileItems.length])

  const onAddFileItem = () => {
    const fileItemAttributes = {
      id: '',
      name: '',
      is_required: false,
      _destroy: false,
      custom_id: generateCustomId
    }

    if(_.isFunction(setCurrentNode)){
      setCurrentNode(prevState => {
        prevState.data[stageForm]['file_items'] = [
          ... prevState.data[stageForm]['file_items'],
          fileItemAttributes
        ]

        return { ... prevState }
      })
    }
  }

  const theadGroupName = () => {
    if(showGroupName){
      return(
        <th className='text-center'>
          { I18n.t('activerecord.attributes.workflow/stage_file_item.group_name') }
          { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_file_item.group_name')) }
        </th>
      )
    }
  }

  const fileItemsAttributes = () => {
    if(hasFileItemsActive){
      return(
        <div className="row">
          <div className="col-12">
            <table className='table text-center'>
              <thead>
                <tr>
                  { theadGroupName() }
                  <th>
                    { I18n.t('activerecord.attributes.workflow/stage_file_item.name') }
                    { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_file_item.name')) }
                  </th>
                  <th>
                    { I18n.t('activerecord.attributes.workflow/stage_file_item.is_required') }
                    { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_file_item.is_required')) }
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { _.map(fileItems, (fileItem, index) => {
                  return (
                    <WorkflowStageFileItemAttributes
                      key={ `stageItemFieldForm-${ index }` }
                      fileItem={ fileItem }
                      index={ index }
                      currentNode={ currentNode }
                      setCurrentNode={ setCurrentNode }
                      configuration={ props.configuration }
                      data={ props?.data }
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return(
        <div className="alert alert-primary text-center" role="alert">
          <i className="fas fa-info-circle mr-2" aria-hidden="true" title="Información"/>
          Aún no has agregado documentos
        </div>
      )
    }
  }

  const addFileButton = () => {
    if(!isNodeShortlist(currentNode)){
      return(
        <Button
          outline
          color='success'
          size="sm"
          onClick={ onAddFileItem }
        >
          { I18n.t('actions.add') }
        </Button>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card bg-real-light border-0">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                <i className="fas fa-copy mr-2"></i>
                { I18n.t('activerecord.models.workflow/stage_file_item.other') }
              </div>
              <div className="text-end">
                { addFileButton() }
              </div>
            </div>
          </div>
          <div className="card-body">
            { fileItemsAttributes() }
          </div>
        </div>
      </div>
    </div>
  )
}


export default WorkflowStageItemFileList;
