import React, {
  useState,
  useMemo,
  useContext
} from 'react';

import {
  FormGroup,
  Label
} from 'reactstrap';


import {
  KARIN_LAW_DEFAULT_GROUP_NAME
} from '../requirement_stage/helper';

import {
  ECONOMIC_CRIME_DEFAULT_GROUP_NAME
} from '../requirement_stage/helper';

import WorkflowRequirementStageFormItemFieldAttributes from '../requirement_stage_form_item/field_attributes';
import WorkflowRequirementStageFormItemFileAttributes from '../requirement_stage_form_item/file_attributes';

import WorkflowRequirementStageFormContext from '../requirement_stage/context/form'

const WorkflowRequirementStageFormAttributes = props => {
  const {
    requirementStage,
    actions,
    stageForm,
    // stageForm: {
    //   field_items: fieldItems,
    //   file_items: fileItems
    // },
    configuration: {
      formName,
      formType,
      defaultRequestParams,
    },
    callbacks: {
      onChangeRequirementStage: callbackOnChangeRequirementStage
    }
  } = props;

  const {
    requirementStageType
  } = useContext(WorkflowRequirementStageFormContext)

  const fieldItems = useMemo(() => {
    if(requirementStageType == 'karinLaw'){
      return _.reject(stageForm.field_items, fieldItem => {
        return _.includes(KARIN_LAW_DEFAULT_GROUP_NAME, fieldItem.group_name)
      })
    } else if(requirementStageType == 'economicCrime'){
      return _.reject(stageForm.field_items, fieldItem => {
        return _.includes(ECONOMIC_CRIME_DEFAULT_GROUP_NAME, fieldItem.group_name)
      })
    }else {
      return stageForm.field_items;
    }
  })

  const fileItems = useMemo(() => {
    if(requirementStageType == 'karinLaw'){
      return _.reject(stageForm.file_items, fileItem => {
        return _.includes(KARIN_LAW_DEFAULT_GROUP_NAME, fileItem.group_name)
      })
    } else if(requirementStageType == 'economicCrime') {
      return _.reject(stageForm.file_items, fileItem => {
        return _.includes(ECONOMIC_CRIME_DEFAULT_GROUP_NAME, fileItem.group_name)
      })
    } else {
      return stageForm.file_items;
    }
  })

  const itemsGrouped = useMemo(() => {
    const items = _.concat(
      fieldItems,
      fileItems
    )

    return _.groupBy(items, 'group_name')
  })


  const onChangeFieldItem = (fieldItem, itemId) => {

    let _stageForm = {
      ...stageForm,
      field_items: _.map(stageForm.field_items, _fieldItem => {
        if(_fieldItem.item.id == itemId){
          return fieldItem
        } else {
          return _fieldItem
        }
      })
    }

    callbackOnChangeRequirementStage({target: { value: _stageForm }}, formType)
  }

  const onChangeFileItem = (fileItem, itemId) => {
    let _stageForm = {
      ...stageForm,
      file_items: _.map(stageForm.file_items, _fileItem => {
        if(_fileItem.item.id == itemId){
          return fileItem
        } else {
          return _fileItem;
        }
      })
    }

    callbackOnChangeRequirementStage({ target: { value: _stageForm }}, formType)
  }

  const groupItemsList = () => {
    return _.map(itemsGrouped, (items, group) => {
      return(
        <div className="card card-material" key={ `group-panel-${ _.camelCase(group || 'without_group') }` }>
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                { group || 'Sin grupo' }
              </div>
            </div>
          </div>

          <div className="card-body row">
            { _.map(items, (item, index) => {
              if(item.data_type == 'file'){
                const _formName = `${ formName }[file_items_attributes]`
                return(
                  <WorkflowRequirementStageFormItemFileAttributes
                    key={ `WorkflowRequirementStageFormItemFileAttributes-${ item?.item?.id || index }` }
                    actions={ actions }
                    requirementStage={ requirementStage }
                    fileItem={ item }
                    configuration={{ ... props.configuration, formName: `${ _formName }[${ item.item.id }]`, index: index}}
                    callbacks={{
                      onChangeRequirementStage: callbackOnChangeRequirementStage
                    }}
                  />
                )
              } else {
                const _formName = `${ formName }[field_items_attributes]`
                return(
                  <WorkflowRequirementStageFormItemFieldAttributes
                    key={ `WorkflowRequirementStageFormItemFieldAttributes-${ item?.item?.id || index }` }
                    requirementStage={ requirementStage }
                    fieldItem={ item }
                    configuration={{ ... props.configuration, formName: `${ _formName }[${ item.item.id }]`, index: index}}
                    callbacks={{
                      onChangeRequirementStage: callbackOnChangeRequirementStage
                    }}
                  />
                )
              }
            })}
          </div>
        </div>
      )
    })
  }

  return(
    <div className="row">
      <div className="col-12">
        { groupItemsList() }
        {/*{ fieldItemsList() }
        { fileItemsList() }*/}
      </div>
    </div>
  )
}

export default WorkflowRequirementStageFormAttributes;
