import React, {
  useMemo,
  useState,
} from 'react';

import {
  Row,
  Col,

  Form,
  FormGroup,
  Input,
  Label,

  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import {
  inputId,
  inputName,
  formatToRut
} from '../../helper/form';

import FieldError from '../../helper/FieldError';

import {
  updatePersonalInformation as updatePersonalInformationService
} from './axios';


const UserProfilePersonalInformationAttributes = props => {

  const {
    configuration: {
      formName = 'profile'
    } = {},
    data: {
      identificationTypes,
      maritalStatuses
    },
  } = props;

  const [user, setUser] = useState(props?.user);

  const onChangeUser = (event, key) => {
    let value = event.target.value;

    const type = event.target.type;

    if(type == 'checkbox'){
      value = event.target.checked;
    }

    setUser(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  // ---------- START EMAIL ----------
  const emailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.email') }>
          <Label
            for={ inputId(formName, 'email') }
            className='required'
          >
            { I18n.t('activerecord.attributes.user.email') }
          </Label>

          <Input
            type='email'
            id={ inputId(formName, 'email') }
            name={ inputName(formName, 'email') }
            value={ user.email || '' }
            invalid={ _.has(user.errors, 'email') }
            disabled={ true }
            required
            // onChange={ event => alert(event.target.value) }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END EMAIL ----------

  // ---------- START NAME ----------
  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.name') }>
          <Label
            for={ inputId(formName, 'name') }
            className='required'
          >
            { I18n.t('activerecord.attributes.user.name') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            value={ user.name || '' }
            invalid={ _.has(user.errors, 'name') }
            onChange={ event => onChangeUser(event, 'name') }
            required
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NAME ----------

  // ---------- START IDENTIFICATION TYPE ----------
  const identificationTypeSelectOptions = useMemo(() => {
    return _.map(identificationTypes, (value, key) => {
      return(
        <option
          key={ `identificationTypeSelectOption-${ key }`}
          value={ key }
        >
          { value }
        </option>
      )
    })
  }, [identificationTypes])

  const identificationTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.identification_type') }>
          <Label
            for={ inputId(formName, 'identification_type') }
            className='required'
          >
            { I18n.t('activerecord.attributes.user.identification_type.one') }
          </Label>

          <Input
            type='select'
            id={ inputId(formName, 'identification_type') }
            name={ inputName(formName, 'identification_type') }
            value={ user.identification_type || '' }
            invalid={ _.has(user.errors, 'identification_type') }
            onChange={ event => onChangeUser(event, 'identification_type') }
            required
          >
            <option value=""></option>
            { identificationTypeSelectOptions }
          </Input>
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END IDENTIFICATION TYPE ----------

  // ---------- START IDENTIFICATION NUMBE ----------
  const isRut = useMemo(() =>{
    return user.identification_type === 'rut'
  }, [user.identification_type] )

  const identificationNumberInput = () => {
    let value = user.identification_number || '';
    let formatedValue = isRut ? formatToRut(value) : value;

    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.identification_number') }>
          <Label
            for={ inputId(formName, 'identification_number') }
            className='required'
          >
            { I18n.t('activerecord.attributes.user.identification_number') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'identification_number') }
            name={ inputName(formName, 'identification_number') }
            value={ formatedValue }
            invalid={ _.has(user.errors, 'identification_number') }
            onChange={ event => onChangeUser(event, 'identification_number') }
            required
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END IDENTIFICATION NUMBE ----------

  // ---------- START ADDRESS ----------
  const addressInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.address') }>
          <Label
            for={ inputId(formName, 'address') }
          >
            { I18n.t('activerecord.attributes.user.address') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'address') }
            name={ inputName(formName, 'address') }
            value={ user.address || '' }
            invalid={ _.has(user.errors, 'address') }
            onChange={ event => onChangeUser(event, 'address') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END ADDRESS ----------

  // ---------- START BIRTHDATE ----------
  const birthdateInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.birthdate') }>
          <Label
            for={ inputId(formName, 'birthdate') }
          >
            { I18n.t('activerecord.attributes.user.birthdate') }
          </Label>

          <Input
            type='date'
            id={ inputId(formName, 'birthdate') }
            name={ inputName(formName, 'birthdate') }
            value={ user.birthdate || '' }
            invalid={ _.has(user.errors, 'birthdate') }
            onChange={ event => onChangeUser(event, 'birthdate') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END BIRTHDATE ----------

  // ---------- START MARITAL STATUS ----------
  const maritalStatusSelectOptions = useMemo(() => {
    return _.map(maritalStatuses, (value, key) => {
      return(
        <option
          key={ `maritalStatusSelectOption-${ key }`}
          value={ key }
        >
          { value }
        </option>
      )
    })
  }, [identificationTypes])

  const maritalStatusSelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.marital_status') }>
          <Label
            for={ inputId(formName, 'marital_status') }
          >
            { I18n.t('activerecord.attributes.user.marital_status.one') }
          </Label>

          <Input
            type='select'
            id={ inputId(formName, 'marital_status') }
            name={ inputName(formName, 'marital_status') }
            value={ user.marital_status || '' }
            invalid={ _.has(user.errors, 'marital_status') }
            onChange={ event => onChangeUser(event, 'marital_status') }
          >
            <option value=""></option>
            { maritalStatusSelectOptions }
          </Input>
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END MARITAL STATUS ----------

  // ---------- START NATIONALITY ----------
  const nationalityInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.nationality') }>
          <Label
            for={ inputId(formName, 'nationality') }
          >
            { I18n.t('activerecord.attributes.user.nationality') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'nationality') }
            name={ inputName(formName, 'nationality') }
            value={ user.nationality || '' }
            invalid={ _.has(user.errors, 'nationality') }
            onChange={ event => onChangeUser(event, 'nationality') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NATIONALITY ----------

    // ---------- START NATIONALITY ----------
  const cellphoneInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(user, 'errors.cellphone') }>
          <Label
            for={ inputId(formName, 'cellphone') }
          >
            { I18n.t('activerecord.attributes.user.cellphone') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'cellphone') }
            name={ inputName(formName, 'cellphone') }
            value={ user.cellphone || '' }
            invalid={ _.has(user.errors, 'cellphone') }
            onChange={ event => onChangeUser(event, 'cellphone') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NATIONALITY ----------

  // ---------- START ACCEPT PERSONAL INFORMATION DECLARATION ----------
    const acceptPersonalInformationDeclarationCheckbox = () => {
    return(
      <FormGroup check>
        <FieldError errors={ _.get(user, 'errors.accept_personal_information_declaration') }>
          <Input
            type='hidden'
            name={ inputName(formName, 'accept_personal_information_declaration') }
            value={ 0 }
          />

          <Input
            type='checkbox'
            id={ inputId(formName, 'accept_personal_information_declaration') }
            name={ inputName(formName, 'accept_personal_information_declaration') }
            checked={ user?.accept_personal_information_declaration || false }
            invalid={ _.has(user, 'errors.accept_personal_information_declaration') }
            value={ 1 }
            onChange={ event => onChangeUser(event, 'accept_personal_information_declaration') }
          />

          <Label htmlFor={ inputId(formName, 'accept_personal_information_declaration') }>
            { I18n.t('activerecord.attributes.user.accept_personal_information_declaration') }
          </Label>
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END ACCEPT PERSONAL INFORMATION DECLARATION ----------

    // ---------- START SUBMIT FORM ----------
  const onConfirmSubmit = event => {
    event.preventDefault();

    swalWithBootstrap.fire({
      title: I18n.t('users.profile.personal_information.update.confirm.title'),
      html: I18n.t('users.profile.personal_information.update.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit(event)
      }
    })
  }

  const getFormData = (form) => {
    const formData = new FormData(form.target);
    return formData;
  }

  const onSubmit = (event) => {
    const formData = getFormData(event);

    updatePersonalInformationService(formData, response => {
      if(response.status == 200){
        location.reload();
      } else {
        setUser(response.data);
      }
    })
  }
  // ---------- END SUBMIT FORM ----------

  return(
    <Form onSubmit={ onConfirmSubmit } >
      <Row>
        <Col md='6' sm='6'>
          { emailInput() }
        </Col>

        <Col md='6' sm='6'>
          { nameInput() }
        </Col>

        <Col md='6' sm='6'>
          { identificationTypeInputSelect() }
        </Col>

        <Col md='6' sm='6'>
          { identificationNumberInput() }
        </Col>

        <Col md='6' sm='6'>
          { addressInput() }
        </Col>

        <Col md='6' sm='6'>
          { birthdateInput() }
        </Col>

        <Col md='6' sm='6'>
          { maritalStatusSelectInput() }
        </Col>

        <Col md='6' sm='6'>
          { nationalityInput() }
        </Col>

        <Col md='6' sm='6'>
          { cellphoneInput() }
        </Col>

        <Col xs='12'>
          { acceptPersonalInformationDeclarationCheckbox() }
        </Col>

        <Col className='text-end'>
          <Button
            color='success'
          >
            Actualizar información
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default UserProfilePersonalInformationAttributes;
