import React, { useState, useEffect, useCallback } from "react";
import { FormGroup, FormFeedback, Input, Label, Button} from "reactstrap";
import I18n from 'i18n-js';

import LaboralBulkMyFilterFieldForm from "./form";

const LaboralBulkMyFilterFieldList = (props) => {
  const {
    bootstrapTableRef,
    currentFilters,
  } = props;

  const formattedCurrentFilter = () => {
    let _filters = []

    _.forEach(currentFilters, (filter, key) => {
      if (_.has(filter, 'filterVal') && !_.isEmpty(filter?.filterVal)) {
        _filters.push(
          {
            name: key,
            caseSensitive: filter?.caseSensitive,
            comparator: filter?.comparator,
            filterType: filter?.filterType,
            filterVal: filter?.filterVal,
          }
        )
      }
    })

    return _filters;
  }

  const columns = bootstrapTableRef?.current?.props?.columns;
  const [filters, setFilters] = useState(formattedCurrentFilter());

  useEffect(()=>{
    if(filters?.length == 0 ){
      onAddField()
    }
  }, [props])

  const onAddField = () => {
    let newFilter = {
      name: '',
      caseSensitive: false,
      comparator: "LIKE",
      filterType: "TEXT",
      filterVal: ""
    }

    setFilters( prevState => {
      return [ ...prevState, newFilter ]
    })
  }

  const isOptionDisabled = useCallback((option) => {
    return _.some(filters, filter => { return filter?.name == option?.value }) || _.some(columns, column => { return column?.dataField == option?.value && column?.filterDisabled });
  }, [filters])

  const addFieldButton = () => {
    return (
      <Button
        outline
        color='success'
        size="sm"
        onClick={ onAddField }
      >
        { I18n.t(`bulk.my_filters.form.add_filter`) }
      </Button>
    )
  }

  const onRemoveField = useCallback((index) => {
    setFilters(prevState => {
      let field = prevState[index]
      _.remove(prevState, (field, i) => i == index)

      currentFilters[field?.name] = { filterVal: '' }
      bootstrapTableRef.current.handleRemoteFilterChange(currentFilters)
      return [...prevState]
    })
  }, [currentFilters]);

  return (
    <div className="card bg-real-light">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <dt>
            { I18n.t(`bulk.my_filters.form.filters_title`) }
          </dt>
          <dt>
            { addFieldButton() }
          </dt>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th className='text-center' width="250">
              { I18n.t(`bulk.my_filters.form.filter_name`) }
            </th>
            <th className='text-center' width="250">
              { I18n.t(`bulk.my_filters.form.filter_value`) }
            </th>
            <th className='text-center' width="50">
            </th>
          </tr>
        </thead>
        <tbody>
          { _.map(filters, function(filter, index){
            return(
              <LaboralBulkMyFilterFieldForm
                key={ `fieldForm-${index}` }
                index={ index }
                filter={ filter }
                setFilters={ setFilters }
                bootstrapTableRef={ bootstrapTableRef }
                isOptionDisabled={ isOptionDisabled }
                currentFilters={ currentFilters }
                onRemoveField={ onRemoveField }
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default LaboralBulkMyFilterFieldList;