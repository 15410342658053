import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';

import axios from 'axios';

import WorkflowRequirementCommentForm from './form'
import WorkflowRequirementCommentList from './list'

const WorkflowRequirementCommentBox = props => {

  const {
    currentUser,
    requirement,
    routes,
    configuration,
    configuration: {
      commentDefaultRequestParams,
      context
    }
  } = props;

  const { indexCommentPath } = routes;

  const [comments, setComments] = useState([])

  const commentPaginatorRef = useRef({
    currentPage: 1
  })

  const indexService = async(page=1) => {
    const requestParams = { ... commentDefaultRequestParams, page: page, format: "json" };

    let promise = axios({
      method: 'get',
      url: indexCommentPath(requestParams),
      headers: {
        "X-CSRF-TOKEN": window.compliance.token
      }
    });

    return await promise;
  }

  const fetchComments = (page = 1) => {
    let fetchService = indexService(page)

    fetchService.then(response => {
      let _comments = _.uniqBy(_.concat(comments, response.data.comments), 'id');

      _comments = _.sortBy(_comments, 'created_at');

      commentPaginatorRef.current = {
        currentPage: response.data.current_page,
        inPage: response.data.in_page,
        total: response.data.total,
        totalPages: response.data.total_page,
      }

      setComments(_comments);

      if(page == 1){
        chatBodyContainerScrollDown()
      }

    }).catch(error => {
      console.log(error);
    })
  }

  const pushComment = (comment) => {
    let _comments = [...comments];

    _comments.push(comment);

    _comments = orderComments(_comments)

    setComments(_comments)

    chatBodyContainerScrollDown()
  }

  const orderComments = useCallback((_comments) => {
    return _.sortBy(
      _.uniqBy(_comments, 'id'),
      'created_t')
  }, [])

  useEffect(() => {
    fetchComments()
  }, [])

  const chatBodyContainerScrollDown = useCallback(() => {
    const objDiv = document.getElementById('chat-body-container');
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [])

  return(
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h2 className="h5 font-weight-bold mb-0">
              <i className="fas fa-clipboard mr-2"></i>
              Inbox
            </h2>
          </div>
        </div>
      </div>
      <div className="card-body chat-container" id='chat-body-container'>
        <WorkflowRequirementCommentList
          comments={ comments }
          commentPaginator={ commentPaginatorRef.current }
          currentUser={ currentUser }
          routes={ routes }
          configuration={ configuration }
          callbacks={{
            fetchComments: fetchComments
          }}
        />
      </div>
      <div className="card-footer">
        <WorkflowRequirementCommentForm
          requirement={ requirement }
          routes={ routes }
          configuration={ configuration }
          callbacks={{
            fetchComments: fetchComments,
            pushComment: pushComment
          }}
        />
      </div>
    </div>
  )
}

export default WorkflowRequirementCommentBox;
