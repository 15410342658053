import React, {
  useMemo,
  useContext
} from 'react';

import {
  Button,
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowDiagramContext from '../diagram_context';

import WorkflowStageFieldItemAttributes from './attributes_field'
import { drawTooltipHelp } from "../../helper/form";

const WorkflowStageItemFieldList = props => {
  const {
    currentNode,
    setCurrentNode,
    fieldItems,
    configuration: {
      stageForm,
      showGroupName
    },
    data: {
      groupNameOptions = []
    }
  } = props;

  const {
    callbacks: {
      isNodeShortlist
    },
  } = useContext(WorkflowDiagramContext);

  const hasFieldItemsActive = useMemo(() => {
    return _.some(fieldItems, item => !item._destroy)
  }, [fieldItems])

  const generateCustomId = useMemo(() => {
    return `${ Math.random().toString(36).slice(2) }-${ moment().toDate().getTime() }-${ fieldItems.length }`;
  }, [fieldItems.length])

  const onAddFieldItem = () => {
    const fieldItemAttributes = {
      id: '',
      name: '',
      data_type: 'text',
      is_required: false,
      _destroy: false,
      custom_id: generateCustomId
    }

    if(_.isFunction(setCurrentNode)){
      setCurrentNode( prevState => {
        prevState.data[stageForm]['field_items'] = [
          ... prevState.data[stageForm]['field_items'],
          fieldItemAttributes
        ]

        return { ... prevState }
      })
    }
  }

  const theadGroupName = () => {
    if(showGroupName){
      return(
        <th className='text-center'>
          { I18n.t('activerecord.attributes.workflow/stage_field_item.group_name') }
          { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_field_item.group_name')) }
        </th>
      )
    }
  }

  const fieldItemsAttributes = () => {
    if(hasFieldItemsActive){
      return(
        <div className="row">
          <div className="col-12">
            <table className='table'>
              <thead>
                <tr>
                  { theadGroupName() }
                  <th className='text-center'>
                    { I18n.t('activerecord.attributes.workflow/stage_field_item.name') }
                    { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_field_item.name')) }
                  </th>
                  <th className='text-center'>
                    { I18n.t('activerecord.attributes.workflow/stage_field_item.data_type.one') }
                    { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_field_item.data_type')) }
                  </th>
                  <th className='text-center'>
                    { I18n.t('activerecord.attributes.workflow/stage_field_item.is_required') }
                    { drawTooltipHelp(I18n.t('activerecord.help.workflow/stage_field_item.is_required')) }
                  </th>
                  <th className='text-center'></th>
                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody>
                { _.map(fieldItems, (fieldItem, index) => {
                  return (
                    <WorkflowStageFieldItemAttributes
                      key={ `WorkflowStageFieldItemAttributes-${ index }` }
                      fieldItem={ fieldItem }
                      currentNode={ currentNode }
                      setCurrentNode={ setCurrentNode }
                      index={ index }
                      configuration={ props?.configuration }
                      data={ props?.data }
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return(
        <div className="alert alert-primary text-center" role="alert">
          <i className="fas fa-info-circle mr-2" aria-hidden="true" title="Información"/>
          Aún no has agregado datos
        </div>
      )
    }
  }

  const addFieldButton = () => {
    if(!isNodeShortlist(currentNode)){
      return(
        <Button
          outline
          color='success'
          size="sm"
          onClick={ onAddFieldItem }
        >
          { I18n.t('actions.add') }
        </Button>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-material bg-real-light border-0">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                <i className="fas fa-tasks mr-2"></i>
                { I18n.t('activerecord.models.workflow/stage_field_item.other') }
              </div>
              <div className="text-end">
                { addFieldButton() }
              </div>
            </div>
          </div>

          <div className="card-body">
            { fieldItemsAttributes() }
          </div>
        </div>
      </div>
    </div>
  )
}


export default WorkflowStageItemFieldList;
