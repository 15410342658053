import React from 'react';
import {
  UncontrolledTooltip
} from 'reactstrap';

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

export const tooltipText = text => {
  const uniqueId = _.uniqueId('tooltip_');
  return(
    <>
      <button
        type="button"
        className="btn btn-link p-0 ml-2"
        id={ uniqueId }
      >
        <i className="fas fa-info-circle text-info fa-lg"></i>
      </button>
      <UncontrolledTooltip target={ uniqueId }>
        { text }
      </UncontrolledTooltip>
    </>
  )
}
