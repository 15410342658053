import React, { useState, useMemo } from 'react';

import {
  Row,
  Col,

  Card,
  CardHeader,
  CardBody,

  UncontrolledCollapse,

  Button
} from 'reactstrap';

import UserProfileSignatureEmailDynamicPinAttributes from './signature_email_dynamic_pin_attributes';
import UserProfileSignatureCustomPinAttributes from './signature_custom_pin_attributes';
import UserProfileSignatureAppDynamicPinAttributes from './signature_app_dynamic_pin_attributes';


const UserProfileDisableSignatureAttributes = props => {
  const {
  } = props;

  const [user, setUser] = useState(props?.user || {})

  const signatureMethod = useMemo(() => {
    return user.signature_method
  }, [])


  const disableMethod = () => {

    if(signatureMethod == 'email_dynamic_pin'){
      return(
        <UserProfileSignatureEmailDynamicPinAttributes
          user={ user }
          configuration={{
            context: 'disable_two_factor'
          }}
          callbacks={{
            setUser: setUser
          }}
        />
      )
    } else if(signatureMethod == 'custom_pin'){
      return(
        <UserProfileSignatureCustomPinAttributes
          user={ user }
          configuration={{
            context: 'disable_two_factor'
          }}
          callbacks={{
            setUser: setUser
          }}
        />
      )
    } else if(signatureMethod == 'app_dynamic_pin'){
      return(
        <UserProfileSignatureAppDynamicPinAttributes
          user={ user }
          configuration={{
            context: 'disable_two_factor'
          }}
          callbacks={{
            setUser: setUser
          }}
        />
        
      )
    }
  }


  return(
      <>
        <Card>
          <CardHeader className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center'>
            <p className="h5 mb-md-0">
              <i className="fas fa-check-circle fa-lg text-success mr-2"/>
              Método de firma configurado
            </p>

            <Button color='warning' id="toggler">
              Modificar Método de firma
            </Button>
          </CardHeader>
        </Card>

        <UncontrolledCollapse toggler="#toggler">
          <Card>
            <CardHeader>
              <p className="h5 card-title font-weight-bold mb-0">
                Modificar método de firma
              </p>
            </CardHeader>

            <CardBody>
              <div className="alert alert-warning">
                <p className="fs-4 fw-bold mb-1">
                  <i className="icon fas fa-exclamation-triangle"></i> Importante
                </p>

                <span>Al desactivar el método de firma, ya no podrás visualizar ni firmar documentos electrónicamente con tu cuenta. Para recuperar estas funciones, deberás configurar un nuevo método de firma.</span>
              </div>

              <p className="lead lh-sm">
                Si requieres modificar tu método de firma, debes desactivar el método de firma actual de tu cuenta siguiendo los siguientes pasos:
              </p>

              <hr/>

              <Row>
                <Col>
                  <p className="h6 text-left mb-3 font-weight-lighter">
                    <strong className='d-block font-weight-bold'>Verifica tu método de firma actual:</strong>
                  </p>

                  { disableMethod() }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      </>
    )
}

export default UserProfileDisableSignatureAttributes;
