import React, {
  useMemo,
  useState
} from 'react';

import {
  Row,
  Col,

  Button,

  Collapse,

  Card,
  CardHeader,
  CardBody,

  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import {
  inputId,
  inputName,
} from '../../../helper/form';

import pinCustomImage from 'images/icons/pin-code.png';

import OtpInputForm from '../../../common/otp_input_form';

import FieldError from '../../../helper/FieldError';

import {
  enableCustomPin as enableCustomPinService,
  disableCustomPin as disableCustomPinService
} from '../axios';

const UserProfileSignatureCustomPinAttributes = props => {

  const {
    user,
    configuration: {
      formName = 'profile',
      context = 'enable_two_factor'
    } = {},
    callbacks: {
      setUser: onSetUser,
      redirectAfterProfileCompleted: redirectAfterProfileCompleted
    }
  } = props;

  const onChangeUser = (event, key) => {
    let value = event.target.value;
    const type = event.target.type;
    if(type == 'checkbox'){
      value = event.target.checked ? value : null
    }

    onSetUser(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeOtpToken = (value, attributeName) => {
    onSetUser(prevState => {
      return { ... prevState, [attributeName]: value }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[signature_pin]`, user.signature_pin || '');
    formData.append(`${ formName }[signature_pin_confirmation]`, user.signature_pin_confirmation || '');

    return formData;
  }

  const customPinService = useMemo(() => {
    if(context == 'enable_two_factor'){
      return enableCustomPinService
    } else if(context == 'disable_two_factor'){
      return disableCustomPinService
    }
  }, [])

  const onSubmit = event => {
    event.preventDefault();

    const formData = getFormData();

    customPinService(formData, response => {
      if(response.status == 200){
        if(_.isFunction(redirectAfterProfileCompleted) && response.data.completed_profile){
          redirectAfterProfileCompleted()
        } else {
          location.reload();
        }
      } else {
        onSetUser(response.data)
      }
    })
  }

  const actionLabel = useMemo(()=>{
    return user.signature_method == "custom_pin" ? "Cambiar" : "Crear";
  }, [])

  const titleHeader = useMemo(() => {
    if(context == 'enable_two_factor'){
      return `${actionLabel} PIN personalizado`
    } else if(context == 'disable_two_factor'){
      return 'Desactivar mi PIN personalizado'
    }
  }, [])

  const submitButton = useMemo(() => {
    if(context == 'enable_two_factor'){
      return (
        <Button
          color='success'
          type='submit'
        >
          { actionLabel + ' método de Firma'}
        </Button>
      )
    } else if(context == 'disable_two_factor'){
      return (
        <Button
          color='danger'
          type='submit'
        >
          Desactivar Método de Firma
        </Button>
      )
    }
  }, [])

  return(
    <Row>
      <Col>
        <Card className='border-0 mb-3 hover-shadow-card options-card'>
          <CardHeader
            className='border-bottom-0 option-btn p-0 border-0 bg-real-light'
            type="button"
            htmlFor={ inputId(formName, 'signature_method_custom_pin') }
          >
            <FormGroup className='pl-0' check>
              <Label className='mb-0 py-3 pl-5 pr-3 d-block text-left w-100'>
                <Input
                  type='checkbox'
                  id={ inputId(formName, 'signature_method_custom_pin') }
                  name={ inputName(formName, 'signature_method_custom_pin') }
                  checked={ user.signature_method == 'custom_pin' }
                  value={ 'custom_pin' }
                  onChange={ event => onChangeUser(event, 'signature_method') }
                  disabled={ user.signature_method == 'dynamic_pin' }
                />
                <div className="d-flex align-items-start justify-content-start pl-2">
                  <img src={ pinCustomImage } alt="Pin Personalizado" style={{width: 40}} className='mr-1'/>
                  <div className='pl-1'>
                    <h3 className="fs-5 font-weight-bold mb-0">
                      { titleHeader }
                    </h3>
                    { function(){
                      if(context == 'enable_two_factor'){
                        return(
                          <>
                            <p className="mb-0 small lh-sm">
                              Puedes { actionLabel }
                              <strong>&nbsp;pin personalizado&nbsp;</strong>
                              que usarás cada vez que necesites firmar tus documentos.
                            </p>
                            <span className="badge badge-primary bg-gradient-4t text-shadow">* Recomendado *</span>
                          </>
                        )
                      }
                    }()}
                  </div>
                </div>
              </Label>
            </FormGroup>
          </CardHeader>

          <Collapse isOpen={ user.signature_method == 'custom_pin'}>
            <Form onSubmit={ onSubmit }>
              <CardBody>
                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 1: </strong>
                      <span>Ingrese su PIN personalizado de 6 digítos</span>
                    </p>

                    <div className="text-center my-3">
                      <FieldError errors={ _.get(user, 'errors.signature_pin') }>
                        <OtpInputForm
                          configuration={{
                            formName: formName,
                            attributeName: 'signature_pin'
                          }}
                          callbacks={{
                            onChangeOtpToken: onChangeOtpToken
                          }}
                          pinCode={ user.signature_pin || '' }
                        />
                      </FieldError>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 2: </strong>
                      <span>Confirme su PIN personalizado de 6 dígitos.</span>
                    </p>

                    <div className="text-center my-3">
                      <FieldError errors={ _.get(user, 'errors.signature_pin_confirmation') }>
                        <OtpInputForm
                          configuration={{
                            formName: formName,
                            attributeName: 'signature_pin_confirmation'
                          }}
                          callbacks={{
                            onChangeOtpToken: onChangeOtpToken
                          }}
                          pinCode={ user.signature_pin_confirmation || '' }
                        />
                      </FieldError>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='text-center'>
                    { submitButton }
                  </Col>
                </Row>
              </CardBody>
            </Form>
          </Collapse>
        </Card>
      </Col>
    </Row>
  )
}

export default UserProfileSignatureCustomPinAttributes;