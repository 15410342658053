import React, {
  useState,
} from 'react';

import {
  Row,
  Col,

  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

import I18n from 'i18n-js';

import UserProfileSignatureMethodAttributes from './signature_method_attributes';
import UserProfileDisableSignatureAttributes from './signature_method/disable_signature_attributes';

const UserProfileSignatureInformationAttributes = props => {

  const {
    user,
    callbacks
  } = props;

  const enableSignatureContent = () => {
    return(
      <Row>
        <Col md="12">
          <p className="lead lh-sm">
            Configura un
            <strong className='font-weight-bold'> método de firma en tu cuenta </strong>
            para poder ver y firmar tus documentos cada vez que lo necesites con un PIN de 6 digitos.
          </p>
          <hr/>

          <Row>
            <Col>
              <p className="h6 text-left mb-3 font-weight-lighter">
                <strong className='d-block font-weight-bold'>Elige el método de firma:</strong>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md='12'>
              <UserProfileSignatureMethodAttributes
                user={ user }
                callbacks={ callbacks }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const disableSignatureContent = () => {
    if(user.signature_method != 'disabled'){
      return(
        <UserProfileDisableSignatureAttributes
          user={ user }
        />
      )
    }
  }

  return(
    <>
      { enableSignatureContent() }
      {/* { disableSignatureContent() } */}
    </>
  )
}

export default UserProfileSignatureInformationAttributes;
